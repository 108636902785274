/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import produce from "immer";
import { timerDelay } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import { batch, useDispatch, useSelector } from "react-redux";
import { ReservationApi } from "../../api/reservationsApi";
import { getAnalytics, logEvent } from "firebase/analytics";

import img1 from "../../assets/icons/image 131.png";
import img2 from "../../assets/icons/image 130.png";
import img4 from "../../assets/icons/image326.png";
import img3 from "../../assets/icons/image 124.png";

const dayjs = require("dayjs");

export default function Services({ event }) {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const loadLocker = useSelector((state) => state.params.loadLocker);
  const dispatch = useDispatch();
  const reservation =
    useSelector((state) => state.reservationReducer.reservationInProgres) || [];

  const selectedServices = reservation?.services || [];
  const [selection, setSelection] = useState(new Set(selectedServices));

  const handleNext = async () => {
    const hasLockerService = reservation?.services?.includes("locker");
    const hasBatteryOrCampingService =
      reservation?.services?.includes("battery") ||
      reservation?.services?.includes("camping");

    const modifiedReservation = produce(reservation, (draft) => {
      if (
        !hasLockerService &&
        reservation.hasOwnProperty("container_details")
      ) {
        delete draft.container_details;
        delete draft.place;
        delete draft.container_serial_nb;

        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.tarif_type;
            delete date.locker_type;
            delete date.service_fee;
          });
        }
      }

      if (!reservation?.services?.includes("battery")) {
        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.battery;
          });
        }
      }

      if (!reservation?.services?.includes("camping")) {
        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.kit_camping;
            delete date.extra;
          });
        }
      }
    });

    dispatch({ type: "UPDATE_RESERVATION", payload: modifiedReservation });

    let maxdate = 0;
    let refdate = 0;
    if (reservation.reservation_date) {
      maxdate =
        dayjs(
          dayjs(reservation.reservation_date)
            .add(timerDelay, "m")
            .utc()
            .format(),
        ).unix() * 1000;
      refdate = dayjs(dayjs().add(0, "s").utc().format()).unix() * 1000;
    }

    if (!reservation.uid || refdate > maxdate) {
      dispatch({
        type: "SET_RESERVATIONNEW_LOAD",
        payload: { loading: true, error: false },
      });

      try {
        const res = await ReservationApi.createReservation(reservation);
        console.log(res);

        if (res) {
          const info = {
            ...reservation,
            uid: res.uid,
            reservation_date: dayjs().utc(),
          };

          logEvent(analytics, "add_to_cart", {
            currency: "EUR",
            value: 0,
            items: [
              {
                item_id: info.event_id,
                item_name: event.event,
                affiliation: event.organizer_name,
              },
            ],
          });

          // reservation.dates.reduce((acc, date) => acc + date.service_fee, 0);

          batch(() => {
            dispatch({
              type: "SET_RESERVATIONNEW_LOAD",
              payload: { loading: false, error: false },
            });
            dispatch({
              type: "CREATE_RESERVATION",
              payload: {
                uid: res.uid,
                reservation_date: dayjs().utc(),
              },
            });
            dispatch({
              type: "SET_CLIENT_DETAILS_PANIER",
              payload: {
                id: reservation.event_id,
                info: info,
              },
            });
            dispatch({
              type: "SET_TIMER",
              payload:
                dayjs(dayjs().add(timerDelay, "m").utc().format()).unix() *
                1000,
            });

            if (hasLockerService && hasBatteryOrCampingService) {
              dispatch({ type: "NEXT_STEP" });
            } else if (hasLockerService) {
              dispatch({ type: "NEXT_STEP" });
            } else if (hasBatteryOrCampingService) {
              dispatch({ type: "SET_STEP", payload: 3 });
            }
          });
        } else {
          NotificationManager.error(
            t("general:errorconnexion"),
            "OUPS !",
            5000,
          );
          dispatch({
            type: "SET_RESERVATIONNEW_LOAD",
            payload: { loading: false, error: true },
          });
        }
      } catch (err) {
        NotificationManager.error(t("general:errorconnexion"), "OUPS !", 5000);
        dispatch({
          type: "SET_RESERVATIONNEW_LOAD",
          payload: { loading: false, error: true },
        });
      }
    } else {
      const info = {
        ...reservation,
        uid: reservation.uid,
        container_id: reservation.dates[0].container_id,
        reservation_date: reservation.reservation_date,
      };

      logEvent(analytics, "add_to_cart", {
        currency: "EUR",
        value: info.dates.reduce((acc, date) => acc + date.service_fee, 0),
        items: [
          {
            item_id: info.event_id,
            item_name: event.event,
            affiliation: event.organizer_name,
          },
        ],
      });

      batch(() => {
        dispatch({
          type: "SET_CLIENT_DETAILS_PANIER",
          payload: {
            id: reservation.event_id,
            info: info,
          },
        });

        dispatch({
          type: "SET_TIMER",
          payload:
            dayjs(
              dayjs(reservation.reservation_date)
                .add(timerDelay, "m")
                .utc()
                .format(),
            ).unix() * 1000,
        });

        if (hasLockerService && hasBatteryOrCampingService) {
          dispatch({ type: "NEXT_STEP" });
        } else if (hasLockerService) {
          dispatch({ type: "NEXT_STEP" });
        } else if (hasBatteryOrCampingService) {
          dispatch({ type: "SET_STEP", payload: 3 });
        }
      });
    }
  };

  const handleBack = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  };

  const handleSelection = (id) => {
    const copy = new Set(selection);
    copy.has(id) ? copy.delete(id) : copy.add(id);
    setSelection(copy);
    dispatch({ type: "SET_SERVICES", payload: [...copy] });
  };
  let batteryAvailable = false;
  if (event?.servicesStock?.battery) {
    event?.servicesStock?.battery.forEach((service) => {
      if (service?.availableStock > 0) {
        batteryAvailable = true;
      }
    });
  }

  let campingAvailable = false;
  if (event?.servicesStock?.camping) {
    event?.servicesStock?.camping.forEach((service) => {
      if (service?.availableStock > 0) {
        campingAvailable = true;
      }
    });
  }

  const services = [
    {
      id: "locker",
      icon: img1,
      name: t("services:pic1"),
      isAvailable: true,
    },
    {
      id: "battery",
      icon: img2,
      name: t("services:pic2"),
      isAvailable: batteryAvailable,
    },
    {
      id: "camping",
      icon: img3,
      name: t("services:pic3"),
      isAvailable: campingAvailable,
    },
    {
      id: "green_cab",
      icon: img4,
      name: t("services:pic4"),
      isAvailable: event?.green_cab,
    },
  ];

  return (
    <div className="w-100 tw-flex tw-flex-col">
      <div
        className="w-100 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
        style={{
          borderColor: "#9A9FA5",
        }}
      >
        <div className="tw-w-[95%] tw-mb-2 tw-flex tw-self-center tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
          {t("services:text")}
        </div>
        <div className="tw-w-full   tw-my-8 lg:tw-px-16  tw-grid tw-grid-cols-1  sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-8">
          {services?.map((data, i) => {
            return (
              <Fragment key={i}>
                {data?.isAvailable && (
                  <div
                    key={i}
                    className="tw-w-full tw-flex tw-flex-col tw-gap-2"
                  >
                    <div
                      style={{
                        border: reservation?.services?.includes(data?.id)
                          ? "1px solid #E7075B"
                          : "1px solid #ddd",
                        color: reservation?.services?.includes(data?.id)
                          ? "green"
                          : "#E7075B",
                      }}
                      className="tw-w-full tw-py-4 tw-text-center tw-border  tw-flex tw-flex-col tw-items-center tw-rounded-xl"
                    >
                      <div className="tw-w-full  tw-text-[24px] md:tw-text-[20px] lg:tw-text-[18px] tw-text-[#000000] tw-font-['Poppins'] tw-font-medium">
                        {data?.name}
                      </div>
                      <img src={data?.icon} height="200px" width="200px" />
                    </div>
                    <button
                      onClick={() => handleSelection(data?.id)}
                      style={{
                        background: reservation?.services?.includes(data?.id)
                          ? "#E7075B"
                          : "#fff",
                        border: reservation?.services?.includes(data?.id)
                          ? "1px solid #fff"
                          : "1px solid #E7075B",
                        color: reservation?.services?.includes(data?.id)
                          ? "#fff"
                          : "#E7075B",
                      }}
                      className="tw-w-full tw-p-3 tw-rounded-xl"
                    >
                      {reservation?.services?.includes(data?.id)
                        ? t("services:btn_start")
                        : t("services:btn_clicked")}
                    </button>
                  </div>
                )}
                {!data?.isAvailable && (
                  <div
                    key={i}
                    className="tw-w-full tw-flex tw-flex-col tw-gap-2"
                  >
                    <div
                      style={{
                        border: "1px solid #ddd",
                        color: "#fff",
                        background: "#f9f9f9",
                      }}
                      className="tw-w-full tw-py-4 tw-text-center tw-border  tw-flex tw-flex-col tw-items-center tw-rounded-xl"
                    >
                      <div
                        style={{ opacity: "0.2" }}
                        className="tw-w-full  tw-text-[24px] md:tw-text-[20px] lg:tw-text-[18px] tw-text-[#000000] tw-font-['Poppins'] tw-font-medium"
                      >
                        {data?.name}
                      </div>
                      <img
                        src={data?.icon}
                        height="200px"
                        style={{ opacity: "0.2" }}
                        width="200px"
                      />
                    </div>
                    <button
                      onClick={() => handleSelection(data?.id)}
                      disabled={true}
                      style={{
                        background: "#f9f9f9",
                        border: "1px solid #000",
                        color: "#000",
                        opacity: "0.2",
                      }}
                      className="tw-w-full tw-p-3 tw-rounded-xl"
                    >
                      {reservation?.services?.includes(data?.id)
                        ? t("services:btn_start")
                        : t("services:btn_clicked")}
                    </button>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>

        <div
          className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
          style={{
            top: -15,
            backgroundColor: "#FFF8F8",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
            {t("services:title")}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center action_buton">
        <button onClick={() => handleBack()} className="prev_btn">
          {t("general:prev")}
        </button>

        <button
          style={{ opacity: reservation?.services?.length > 0 ? "1" : "0.2" }}
          disabled={reservation?.services?.length > 0 ? false : true}
          className="next_btn"
          onClick={handleNext}
        >
          {loadLocker.loading ? (
            <CircularProgress size={30} sx={{ color: "#fff" }} />
          ) : (
            t("general:next")
          )}
        </button>
      </div>
    </div>
  );
}
