import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "./redux/reducers/events";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

import Home from "./components/homepage/Home";
import Reservation from "./components/pages/Reservation";
import SuccessPage from "./components/pages/SuccessPage";
import CancelPage from "./components/pages/CancelPage";
import QrcodePage from "./components/pages/QrcodePage";

// import HowItWork from './components/pages/howItWork';
import Jobs from "./components/pages/Jobs";
import JobDetails from "./components/pages/JobDetails";
import NotFound from "./components/pages/404";
import orderEventByDate from "./utils";
// import Pros from './components/pages/Pros';
// import ProDetails from './components/pages/ProsDetails';
import Rgpd from "./components/pages/rgpd";
import Cgu from "./components/pages/cgu";
import Cgv from "./components/pages/cgv";
import Faq from "./components/pages/faq";
import OnParleDeNous from "./components/pages/aboutus";
import MentionLegal from "./components/pages/mentionLegal";

import "./App.scss";
import Dialog from "@mui/material/Dialog";
import ReservationDialog from "./components/dialog/reservationDialog";
import DeletePanierDialog from "./components/dialog/deletePanierDialog";
import Contact from "./components/homepage/Contact";
import AnnulerDialog from "./components/dialog/deleteReservationDialog";
import PostulerDialog from "./components/dialog/postuleJobs";
// import axios from 'axios';
import { getSlides } from "./redux/reducers/slider";
import OnlineHelp from "./components/pages/onlineHelp";
import DownloadAppPage from "./components/pages/downloadAppPage";

const dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
require("dayjs/locale/fr");
require("dayjs/locale/en");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  const lng = useSelector((state) => state.params.langage);
  const dialog = useSelector((state) => state.params.dialog);
  const eventData =
    useSelector((state) => state.eventReducer.filteredEvents) || [];

  const dispatch = useDispatch();

  const i18nextLng = localStorage.getItem("i18nextLng");
  dayjs.locale(lng);

  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", payload: i18nextLng });
    dispatch(getEvents());
    dispatch(getSlides());
  }, []);

  return (
    <BrowserRouter>
      <Navigation />

      <Routes>
        <Route
          exact
          path="/"
          element={<Home events={orderEventByDate(eventData)} />}
        />

        <Route exact path="reservation/:id" element={<Reservation />} />
        <Route exact path="reservation/paiement" element={<SuccessPage />} />

        <Route
          exact
          path="jobs"
          element={
            lng?.slice(0, 2) === "fr" ? (
              <Jobs />
            ) : (
              <Home events={orderEventByDate(eventData)} />
            )
          }
        />
        <Route
          exact
          path="jobs/:id"
          element={
            lng?.slice(0, 2) === "fr" ? (
              <JobDetails />
            ) : (
              <Home events={orderEventByDate(eventData)} />
            )
          }
        />

        <Route exact path="RGPD" element={<Rgpd />} />
        <Route exact path="CGU" element={<Cgu />} />
        <Route exact path="CGV" element={<Cgv />} />
        <Route exact path="Mention_legal" element={<MentionLegal />} />
        <Route exact path="On_parle_de_nous" element={<OnParleDeNous />} />

        <Route exact path="FAQ" element={<Faq />} />
        <Route exact path="HELP" element={<OnlineHelp />} />

        <Route exact path="contact" element={<Contact />} />

        {/* <Route exact path="pros" element={<Pros/>} />
        <Route exact path="pros/:id" element={<ProDetails/>} /> */}

        <Route exact path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />

        <Route exact path="/r/:id" element={<CancelPage />} />
        <Route exact path="/q/:id" element={<QrcodePage />} />
        <Route exact path="/d-q" element={<DownloadAppPage />} />
      </Routes>

      <Footer />

      <Dialog open={dialog.active}>
        {dialog.view === "reservationD" && <ReservationDialog />}
        {dialog.view === "deletePa" && (
          <DeletePanierDialog datas={dialog.value} />
        )}
        {dialog.view === "cancelRe" && <AnnulerDialog id={dialog.value.uid} />}
        {dialog.view === "postuler" && <PostulerDialog jobs={dialog.value} />}
      </Dialog>
    </BrowserRouter>
  );
};

export default App;
