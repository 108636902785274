import { useDispatch, useSelector, batch } from "react-redux";
import { daysBetween, rangeOfDates, getReservationDates } from "../../utils";
import { FaCalendarAlt } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setReservationDate,
  setReservationPass,
} from "../../redux/reducers/reservations";
import { getLockersStock } from "../../redux/reducers/lockers";
import { getContainersDetails } from "../../redux/reducers/containers";
import { NotificationManager } from "react-notifications";
import SkeltonPickDate from "../skelton/SkeltonPickDate";
import "./pickdate.css";
import { useEffect } from "react";
import { LockersApi } from "../../api/lockersApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const dayjs = require("dayjs");

const shortDate = (date) => {
  const d = new Date(date);
  return d.toISOString().slice(0, 10);
};

const PickDate = ({ event }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reservation =
    useSelector((state) => state.reservationReducer.reservationInProgres) || [];
  const containerDetailsLoad = useSelector(
    (state) => state.containers.containerDetailsLoad,
  );
  const loadLocker = useSelector((state) => state.params.loadLocker);
  const container_id = event.container_id;
  const nbOfDay = daysBetween(event.start_date, event.end_date);
  const dates = rangeOfDates(event.start_date, event.end_date);

  let selectedDate = [];
  if (reservation.dates) {
    selectedDate = getReservationDates(reservation.dates);
  }

  const dispatch = useDispatch();

  const getContainerDetails = () => {
    dispatch(getContainersDetails(container_id));
  };

  useEffect(() => {
    getContainerDetails();
  }, [container_id]);

  const addLongdate = (start_date, end_date) => {
    const sd = start_date;
    const ed = end_date;
    const dates = rangeOfDates(sd, ed);
    // dispatch(getLockersStock(container_id, sd))
    dispatch(setReservationPass(dates.map((date) => shortDate(date))));
  };

  const handleClick = (date) => {
    const d = shortDate(date);

    dispatch(setReservationDate(shortDate(d)));
  };

  const handleNext = async () => {
    if (!reservation.dates || reservation.dates.length === 0) {
      return NotificationManager.error(
        t("pickdate:errormindate"),
        "Oups !",
        5000,
      );
    }

    dispatch({ type: "LOAD_LOCKER", payload: { loading: true, error: false } });
    try {
      const res = await LockersApi.getLockerStock(reservation.event_id);

      if (res) {
        const panierAdd = {
          event: event,
          reservation: {
            ...reservation,
            lockerStock: res,
          },
        };

        batch(() => {
          dispatch({
            type: "LOAD_LOCKER",
            payload: { loading: false, error: false },
          });
          dispatch({ type: "GET_LOCKERS_STOCK", payload: res });
          dispatch({ type: "NEXT_STEP" });
          dispatch({
            type: "ADD_RESERVATION_TO_PANIER",
            payload: panierAdd,
          });
        });
      } else {
        NotificationManager.error(t("general:errorconnexion"), "Erreur", 5000);
        dispatch({
          type: "LOAD_LOCKER",
          payload: { loading: false, error: true },
        });
      }
    } catch {
      NotificationManager.error(t("general:errorconnexion"), "Erreur", 5000);
      dispatch({
        type: "LOAD_LOCKER",
        payload: { loading: false, error: true },
      });
    }
  };

  const handleBack = () => {
    // navigate to home page
    window.location.href = "/";
    //dispatch({ type: "SET_STEP", payload: 0 });
  };

  const mindate = dayjs(dayjs().utc().format()).unix() * 1000;
  const mindateOverHour =
    dayjs(dayjs().utc().format("YYYY-MM-DD")).unix() * 1000;
  const maxdate = dayjs(dayjs().add(7, "day").utc().format()).unix() * 1000;

  const mindateEvent =
    dayjs(dayjs(event.start_date).utc().format()).unix() * 1000;
  const maxdateEvent =
    dayjs(dayjs(event.end_date).utc().format()).unix() * 1000;

  if (containerDetailsLoad.loading) {
    return (
      <div className=" tw-w-full tw-items-center tw-flex tw-flex-col tw-h-[500px] ">
        <SkeltonPickDate />
      </div>
    );
  }

  if (containerDetailsLoad.error) {
    return (
      <div className=" tw-w-full tw-items-center tw-flex tw-flex-col tw-justify-center tw-h-[300px] ">
        <span>{t("pickdate:errorressa")}</span>

        <button
          onClick={() => getContainerDetails()}
          className=" tw-bg-[#E7075B] tw-font-medium tw-text-white tw-mt-6 tw-rounded-lg md:tw-w-[90%] tw-w-[95%] tw-h-[40px] tw-self-center hover:shadow-md focus:ring-2"
        >
          {t("general:retry")}
        </button>
      </div>
    );
  }

  if (event.event_duration === 0) {
    return (
      <div className=" tw-w-full tw-items-center tw-flex tw-flex-col tw-justify-center tw-h-[300px] ">
        <span>{t("general:eventclosed")}</span>

        <button
          onClick={() => navigate(-1)}
          className=" tw-bg-[#E7075B] tw-font-medium tw-text-white tw-mt-6 tw-rounded-lg md:tw-w-[90%] tw-w-[95%] tw-h-[40px] tw-self-center hover:shadow-md focus:ring-2"
        >
          {t("general:return")}
        </button>
      </div>
    );
  }

  if (mindate > maxdateEvent) {
    return (
      <div className=" tw-w-full tw-items-center tw-flex tw-flex-col tw-justify-center tw-h-[300px] ">
        <span>{t("general:eventclosed")}</span>
        {/* <span>
                    {t("pickdate:endevent1")} {' '} <span className=" tw-font-bold" >{dayjs(event.end_date).utc().format("dddd DD MMMM YYYY")}</span> {' '} {t("pickdate:endevent2")} 
                </span> */}

        <button
          onClick={() => navigate(-1)}
          className=" tw-bg-[#E7075B] tw-font-medium tw-text-white tw-mt-6 tw-rounded-lg md:tw-w-[90%] tw-w-[95%] tw-h-[40px] tw-self-center hover:shadow-md focus:ring-2"
        >
          {t("general:return")}
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column date-pick-div tw-mt-6 md:tw-mt-0 ">
      {!event?.intallation_type ? (
        <section>
          {mindate <= mindateEvent && (
            <div
              className="w-100 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
              style={{
                marginTop: 10,
                borderColor: "#9A9FA5",
              }}
            >
              <div className="tw-w-[95%] tw-mb-2 tw-flex tw-self-center tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
                {t("pickdate:passdescrip")}
              </div>

              <div
                className={
                  "tw-ring-[0.5px] date-pick-section my-2 tw-w-[95%] tw-self-center tw-flex-wrap tw-min-h-[70px] tw-gap-y-[10px] tw-py-[10px] tw-justify-center " +
                  (reservation.dates && reservation.pass
                    ? "tw-ring-green-200 tw-bg-slate-100"
                    : "tw-ring-[#DDDDEB]")
                }
              >
                <div className=" tw-flex tw-flex-1 tw-items-center tw-mx-[10px]  ">
                  {reservation.dates && reservation.pass ? (
                    <AiFillCheckCircle
                      size={28}
                      style={{ marginRight: 5 }}
                      className=" tw-text-green-500"
                    />
                  ) : null}

                  <span className=" tw-font-['Poppins'] tw-font-[500] tw-leading-[20px] tw-text-[14px] ">
                    {nbOfDay}{" "}
                    {nbOfDay > 1 ? t("pickdate:days") : t("pickdate:day")}
                  </span>
                </div>

                {reservation.dates && reservation.pass ? (
                  <button
                    onClick={() =>
                      addLongdate(event.start_date, event.end_date)
                    }
                    type="button"
                    className="selected"
                  >
                    {t("general:cancel")}
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      addLongdate(event.start_date, event.end_date)
                    }
                    type="button"
                    className="non-selected"
                  >
                    {t("general:select")}
                  </button>
                )}
              </div>

              <div
                className=" position-absolute px-3 d-flex tw-mx-4 tw-self-start"
                style={{
                  top: -15,
                  backgroundColor: "#FFF8F8",
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
                  {nbOfDay > 1
                    ? t("pickdate:passtitle1")
                    : t("pickdate:passtitle2")}
                </span>
              </div>
            </div>
          )}

          {nbOfDay >= 1 && (
            <div
              className="w-100 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
              style={{
                marginTop: 70,
                borderColor: "#9A9FA5",
              }}
            >
              <div className="tw-w-[95%] tw-mb-2 tw-flex tw-self-center tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
                {t("pickdate:daydescrip")}
              </div>

              {dates.map((date, index) => {
                const dateTimestamps =
                  dayjs(dayjs(date).utc().format()).unix() * 1000;
                const dateTimestampsOverHour =
                  dayjs(dayjs(date).utc().format("YYYY-MM-DD")).unix() * 1000;

                if (
                  dateTimestamps >= mindate ||
                  dateTimestamps >= mindateOverHour
                ) {
                  return (
                    <div
                      key={index}
                      className={
                        "tw-ring-[0.5px] date-pick-section my-2 tw-w-[95%] tw-self-center tw-flex-wrap tw-min-h-[70px] tw-gap-y-[10px] tw-py-[10px] tw-justify-center " +
                        (reservation.dates &&
                        !reservation.pass &&
                        selectedDate.includes(shortDate(date))
                          ? "tw-ring-green-200 tw-bg-slate-100"
                          : "tw-ring-[#DDDDEB]")
                      }
                    >
                      <div className="tw-flex tw-flex-1 tw-items-center tw-mx-[10px] ">
                        {reservation.dates &&
                        !reservation.pass &&
                        selectedDate.includes(shortDate(date)) ? (
                          <AiFillCheckCircle
                            size={18}
                            style={{ marginRight: 5 }}
                            className=" tw-text-green-500"
                          />
                        ) : (
                          <FaCalendarAlt
                            size={18}
                            style={{ marginRight: 10 }}
                            className="tw-text-[#9A9FA5]"
                          />
                        )}

                        <span className=" tw-font-['Poppins'] tw-font-[500] tw-leading-[20px] tw-text-[14px] ">
                          {/* {dateTimestampsOverHour === mindateOverHour ?
                                                            "Aujourd'hui" */}
                          {dayjs(date).utc().format("dddd DD MMMM YYYY")}
                        </span>
                      </div>

                      {reservation.dates &&
                      !reservation.pass &&
                      selectedDate.includes(shortDate(date)) ? (
                        <button
                          onClick={() => handleClick(date)}
                          type="button"
                          className="selected "
                        >
                          {t("general:cancel")}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleClick(date)}
                          type="button"
                          className="non-selected"
                        >
                          {t("general:select")}
                        </button>
                      )}
                    </div>
                  );
                }
              })}

              <div
                className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
                style={{
                  top: -15,
                  backgroundColor: "#FFF8F8",
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
                  {t("pickdate:daytitle")}
                </span>
              </div>
            </div>
          )}
        </section>
      ) : (
        <section>
          {nbOfDay > 1 && (
            <div
              className="w-100 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
              style={{
                marginTop: 20,
                borderColor: "#9A9FA5",
              }}
            >
              <div className="tw-w-[95%] tw-mb-2 tw-flex tw-self-center tw-font-['Poppins'] tw-font-medium ">
                {t("pickdate:daydescrip")}
              </div>

              {dates.map((date, index) => {
                const dateTimestamps =
                  dayjs(dayjs(date).utc().format()).unix() * 1000;
                const dateTimestampsOverHour =
                  dayjs(dayjs(date).utc().format("YYYY-MM-DD")).unix() * 1000;

                if (
                  (dateTimestamps >= mindate && dateTimestamps <= maxdate) ||
                  (dateTimestamps >= mindateOverHour &&
                    dateTimestamps <= maxdate)
                ) {
                  return (
                    <div
                      key={index}
                      className={
                        "tw-ring-[0.5px] date-pick-section my-2 tw-w-[95%] tw-self-center tw-flex-wrap tw-min-h-[70px] tw-gap-y-[10px] tw-py-[10px] tw-justify-center " +
                        (reservation.dates &&
                        !reservation.pass &&
                        selectedDate.includes(shortDate(date))
                          ? "tw-ring-green-200 tw-bg-slate-100"
                          : "tw-ring-[#DDDDEB]")
                      }
                    >
                      <div className="tw-flex tw-flex-1 tw-items-center tw-mx-[10px]">
                        {reservation.dates &&
                        !reservation.pass &&
                        selectedDate.includes(shortDate(date)) ? (
                          <AiFillCheckCircle
                            size={28}
                            style={{ marginRight: 5 }}
                            className=" tw-text-green-500"
                          />
                        ) : (
                          <FaCalendarAlt
                            size={30}
                            style={{ marginRight: 10 }}
                            className="tw-text-[#9A9FA5]"
                          />
                        )}

                        <span className=" tw-font-['Poppins'] tw-font-[500] tw-leading-[20px] ">
                          {/* {dateTimestampsOverHour === mindateOverHour ?
                                                            "Aujourd'hui"
                                                        :     */}
                          {dayjs(date).utc().format("dddd DD MMMM YYYY")}
                        </span>
                      </div>

                      {reservation.dates &&
                      !reservation.pass &&
                      selectedDate.includes(shortDate(date)) ? (
                        <button
                          onClick={() => handleClick(date)}
                          type="button"
                          className="selected"
                        >
                          {t("general:cancel")}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleClick(date)}
                          type="button"
                          className="non-selected"
                        >
                          {t("general:select")}
                        </button>
                      )}
                    </div>
                  );
                }
              })}

              <div
                className=" position-absolute px-3 d-flex"
                style={{
                  top: -15,
                  left: 50,
                  backgroundColor: "#FFF8F8",
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait">{t("pickdate:daytitle")}</span>
              </div>
            </div>
          )}
        </section>
      )}

      <div className="d-flex align-items-center action_buton">
        <button
          disabled={loadLocker.loading}
          onClick={() => handleBack()}
          className="prev_btn"
        >
          {t("general:prev")}
        </button>

        <button
          disabled={loadLocker.loading}
          onClick={() => handleNext()}
          className="next_btn"
        >
          {loadLocker.loading ? (
            <CircularProgress size={30} sx={{ color: "#fff" }} />
          ) : (
            t("general:next")
          )}
        </button>
      </div>
    </div>
  );
};

export default PickDate;
