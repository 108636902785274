import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSlides } from "../../redux/reducers/slider";

import "./topSlider.css";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";

const TopSlider = (args) => {
  const load = useSelector((state) => state.params.loadSlides);
  const allslides = useSelector((state) => state.sliderReducer.allslides) || [];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === allslides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? allslides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = allslides.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <div
          onClick={() =>
            item?.event_id
              ? window.location.assign(`/reservation/${item?.event_id}`)
              : ""
          }
          className=" tw-relative tw-w-full cursor-pointer tw-flex tw-justify-center tw-items-center tw-h-[250px] md:tw-h-[550px] tw-bg-slate-300 tw-rounded-md tw-overflow-hidden "
        >
          <div className="carousel-caption2 tw-absolute tw-w-[70%] tw-flex tw-justify-center tw-items-center tw-mx-[15%] ">
            {/* <div className=" tw-bg-[rgba(10,10,10,0.5)] tw-py-8 tw-px-8 tw-rounded-md tw-flex-col tw-flex tw-justify-center tw-items-center">
              
              <h3 className="md:tw-text-[55px] tw-text-[18px] tw-font-bold tw-line-clamp-2 " >{item.caption}</h3>
              <p className="md:tw-text-[26px] tw-text-[14.5px] tw-mt-2 tw-font-semibold tw-line-clamp-2 " >{item.subtitle}</p>
              <small className="md:tw-text-[14px] tw-mt-2 tw-text-[11px] tw-line-clamp-2" >{item.slot}</small>

            </div> */}
          </div>

          <img
            className=" tw-w-full tw-h-full tw-object-cover"
            src={`${process.env.REACT_APP_PUBLIC_URL}/cms/slide/image/${item.image}`}
            alt={item.altText}
          />
        </div>
      </CarouselItem>
    );
  });

  return (
    <>
      <Carousel
        activeIndex={activeIndex}
        interval={3000}
        next={next}
        previous={previous}
        {...args}
      >
        <CarouselIndicators
          items={allslides}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </>
  );
};

export default TopSlider;
