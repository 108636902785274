import { takeLatest, put, call, all } from "redux-saga/effects";
import { ReservationApi } from "../../api/reservationsApi";

function* timerautocomplete(payload) {
  yield all([
    put({ type: "SET_TIMER", payload: false }),
    put({ type: "SET_BACKDROP", payload: true }),
  ]);

  //api to delete reservation on database
  const retour = yield call(() =>
    ReservationApi.deleteReservation(payload.payload.uid),
  );

  let data = {};
  data.event_id = payload.payload.event_id;
  data.dates = [];
  payload.payload.dates.forEach(function (item) {
    data.dates.push({
      container_id: item.container_id,
      date: item.date,
    });
  });
  data.container_id = payload.payload.container_id;
  if (payload.payload?.pass) {
    data.pass = payload.payload.pass;
  }

  if (retour) {
    yield all([
      put({ type: "SET_BACKDROP", payload: false }),
      // put({type: 'SET_CLIENT_DETAILS', payload: data}),
      put({ type: "RESET_RESERVATION_PROGRES", payload: data }),
      put({ type: "SET_STEP", payload: 0 }),
      put({
        type: "SET_DIALOG",
        payload: { active: true, view: "reservationD" },
      }),
    ]);
  } else {
    yield all([
      put({ type: "SET_BACKDROP", payload: false }),
      // put({type: 'SET_CLIENT_DETAILS', payload: data}),
      put({ type: "RESET_RESERVATION_PROGRES", payload: data }),
      put({ type: "SET_STEP", payload: 0 }),
      put({
        type: "SET_DIALOG",
        payload: { active: true, view: "reservationD" },
      }),
    ]);
  }
}

function* timercomplete(payload) {
  yield all([
    put({ type: "SetLoadUsers", value: true }),
    put({ type: "SetKeywordSearchUsers", value: payload.value.keyword.trim() }),
  ]);
}

export const timersappSaga = [
  takeLatest("TIMERAUTOCOMPLETE", timerautocomplete),
  takeLatest("TIMERCOMPLETE", timercomplete),
];
