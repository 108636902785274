import BeatLoader from "react-spinners/BeatLoader";
import "./paiement.css";
import React, { useState, useEffect } from "react";
import { useSelector, dispatch, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import CheckoutForm from "./stripe/CheckoutForm";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PayementApi } from "../../api/payementApi";
import {
  addPromoCode,
  updateReseration,
} from "../../redux/reducers/reservations";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { goToPay } from "../../api/vivaPaymentApi";
import batery from "../../assets/icons/image 130.png";
import batts from "../../assets/icons/s+/batterie.png";
import solo from "../../assets/icons/s+/kit solo.png";
import duo from "../../assets/icons/s+/Kit duo.png";
import quatro from "../../assets/icons/s+/kit quatro.png";
import galcie from "../../assets/icons/s+/Glaciere.png";
import oreil from "../../assets/icons/s+/oreiller.png";
import lamp from "../../assets/icons/s+/lampe.png";
import chaise from "../../assets/icons/s+/chaise.png";
import tabou from "../../assets/icons/s+/tabouret.png";

const baseUrl = `${process.env.REACT_APP_ROOT_URL}`;

const dayjs = require("dayjs");

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Paiement = ({ event }) => {
  const { t } = useTranslation();
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );

  const analytics = getAnalytics();

  // get site language
  const lng = useSelector((state) => state.params.langage);

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState(0);

  const [haveTopay, setHaveTopay] = useState(true);

  const [clientSecret, setClientSecret] = useState("");

  const [promocode, setPromocode] = useState("");
  const [promocodeload, setPromocodeload] = useState({
    loading: false,
    error: false,
    message: "",
    data: null,
  });

  const batt = [
    {
      id: "battery1",
      name: "BATTERIE STANDARD",
      price: 6,
      icon: batery,
    },
    {
      id: "battery2",
      name: "BATTERIE ILLIMITEE",
      price: 10,
      icon: batts,
    },
  ];

  const kit = [
    {
      id: "ks1",
      name: "KIT SOLO",
      price: 89,
      icon: solo,
    },
    {
      id: "ks2",
      name: "KIT DUO",
      price: 159,
      icon: duo,
    },
    {
      id: "ks3",
      name: "KIT QUATRO",
      price: 299,
      icon: quatro,
    },
  ];

  const extras = [
    {
      id: "et1",
      name: "GLACIERE 30 LITRES",
      price: 30,
      icon: galcie,
    },
    {
      id: "et2",
      name: "OREILLER 40/40 CM",
      price: 18,
      icon: oreil,
    },
    {
      id: "et3",
      name: "LAMPE TORCHE",
      price: 5,
      icon: lamp,
    },
    {
      id: "et4",
      name: "CHAISE PLIANTE",
      price: 20,
      icon: chaise,
    },
    {
      id: "et5",
      name: "TABOURET",
      price: 10,
      icon: tabou,
    },
  ];

  console.log({ reservation });

  const [term, setTerms] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    setAmount(total + 0.8);
    if (reservation.dates) {
      const service_fee =
        reservation.dates.reduce((acc, date) => acc + date.service_fee, 0) || 0;
      const battery_fee =
        reservation.dates
          .filter((date) => date.hasOwnProperty("battery")) // Filtrer les dates qui ont la propriété battery
          .flatMap((date) => date.battery) // Aplatir les batteries de toutes les dates en un seul tableau
          .reduce((acc, batt) => acc + batt.price, 0) || 0;

      const kit_camping =
        reservation.dates[0].kit_camping?.reduce(
          (acc, kit) => acc + kit.price,
          0,
        ) || 0;
      const extra =
        reservation.dates[0].extra?.reduce(
          (acc, extra) => acc + extra.price,
          0,
        ) || 0;

      setTotal(service_fee + battery_fee + kit_camping + extra);
    }
    if (promocodeload.data) {
      if (parseInt(promocodeload.data.discount) === 100) {
        setAmount(total - (total * promocodeload.data.discount) / 100);
        setHaveTopay(false);
      } else {
        setAmount(total - (total * promocodeload.data.discount) / 100 + 0.8);
      }
    }
  }, [promocodeload.data, reservation.dates, total]);

  const handleSuccess = () => {
    logEvent(analytics, "begin_checkout", {
      currency: "EUR",
      value: amount,
      coupon: promocodeload.data.code,
      items: [
        {
          item_id: reservation.event_id,
          item_name: event.event,
          affiliation: event.organizer_name,
        },
      ],
    });

    const reservation_to_update = {
      ...reservation,
      code_promo: promocodeload.data.code,
      total: amount,
      discount: promocodeload.data.discount,
      lng: lng?.slice(0, 2),
    };

    dispatch(updateReseration(reservation_to_update));

    dispatch(
      addPromoCode({
        code_promo: promocodeload.data.code,
        total: amount,
        discount: promocodeload.data.discount,
      }),
    );

    navigate(`../reservation/paiement`, { state: { prov: true } });
  };

  const handlePayment = async () => {
    if (promocodeload.data) {
      logEvent(analytics, "begin_checkout", {
        currency: "EUR",
        value: amount,
        coupon: promocodeload.data.code,
        items: [
          {
            item_id: reservation.event_id,
            item_name: event.event,
            affiliation: event.organizer_name,
          },
        ],
      });
      dispatch(
        addPromoCode({
          code_promo: promocodeload.data.code,
          total: amount,
          discount: promocodeload.data.discount,
        }),
      );
    } else {
      logEvent(analytics, "begin_checkout", {
        currency: "EUR",
        value: amount,
        items: [
          {
            item_id: reservation.event_id,
            item_name: event.event,
            affiliation: event.organizer_name,
          },
        ],
      });
      dispatch(
        addPromoCode({ code_promo: null, total: amount, discount: null }),
      );
    }
    setLoading(true);
    localStorage.setItem(
      "newReservation",
      JSON.stringify({ ...reservation, total: amount, lng: lng?.slice(0, 2) }),
    );
    dispatch(
      updateReseration({
        ...reservation,
        total: amount,
        lng: lng?.slice(0, 2),
      }),
    );

    let payload = {
      items: [
        {
          customerTrns: "Reservation payment",
          fullName:
            reservation?.client_name + " " + reservation?.client_last_name,
          countryCode: "US",
          id: reservation?.client_email,
          amount: amount,
          reservation_uid: reservation?.uid,
          // event_id: reservation.event_id,
          lng: lng?.slice(0, 2),
        },
      ],
    };

    try {
      const { data } = await goToPay(payload);
      if (data?.success) {
        setLoading(false);
        dispatch({ type: "PUT_TIMER_STATUS", payload: "pause" });
        window.location.assign(
          `https://www.vivapayments.com/web/checkout?ref=${data?.data?.orderCode}&color=E7075B`,
        );
      }
    } catch (error) {
      dispatch({ type: "PUT_TIMER_STATUS", payload: "play" });
      setLoading(false);
    }
  };

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#E7075B",
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  const handlePromoCode = async () => {
    setPromocodeload({ loading: true, error: false, message: "" });

    const dataPromo = await PayementApi.verifyPromoCode(
      reservation.event_id,
      promocode,
    );
    if (dataPromo) {
      if (!dataPromo.status) {
        setPromocodeload({
          loading: false,
          error: true,
          message: dataPromo.message,
        });
      } else {
        setPromocodeload({
          loading: false,
          error: false,
          message: dataPromo.message,
          data: dataPromo.data || null,
        });
      }
    } else {
      setPromocodeload({
        loading: false,
        error: true,
        message: t("pickdate:errorressa"),
      });
    }
  };

  return (
    <div className="d-flex flex-column tw-w-full container tw-items-center ">
      <div className="tw-flex tw-flex-col tw-mt-5 md:tw-mt-0 lg:tw-flex-row tw-justify-between tw-w-full">
        <div className=" tw-ring-1 tw-ring-slate-200 tw-flex tw-flex-col lg:tw-w-[58%] tw-w-[100%] tw-bg-white tw-rounded-lg ">
          <h1 className=" tw-text-xl tw-mt-3 tw-mb-1 tw-pl-3 tw-font-medium tw-font-['Poppins']">
            {t("navigation:Mycarts")}
          </h1>
          <br />
          {(reservation.dates[0].kit_camping || reservation.dates[0].extra) && (
            <div
              className="tw-ring-[0.5px] my-2 tw-w-[95%] fieldset position-relative tw-self-center tw-flex-wrap tw-min-h-[70px] tw-gap-y-[10px] tw-py-[10px] tw-justify-center"
              style={{
                borderColor: "#9A9FA5",
              }}
            >
              <div className="d-flex tw-self-center tw-flex-wrap align-items-center justify-content-between md:tw-px-[2.5%] tw-px-[5px] tw-w-full tarif-container tw-my-3">
                <div className="tw-w-full tw-flex tw-flex-col tw-items-end tw-justify-end">
                  {reservation?.dates[0].kit_camping && (
                    <div className="">
                      <span className=" tw-font-['Poppins'] tw-font-semibold tw-text-[12px] tw-ml-2 ">
                        {reservation?.dates[0].kit_camping?.map((kit) => (
                          <p className=" tw-font-['Poppins'] tw-font-semibold tw-text-[12px] tw-ml-2 ">
                            • 1 x {kit.name} = {kit.price + " €"}
                          </p>
                        ))}
                      </span>
                    </div>
                  )}

                  {reservation.dates[0].extra && (
                    <div className="">
                      {reservation?.dates[0].extra?.map((extra) => (
                        <p className=" tw-font-['Poppins'] tw-font-semibold tw-text-[12px] tw-ml-2 ">
                          • 1 x {extra.name} = {extra.price + " €"}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                <button
                  onClick={() => dispatch({ type: "SET_STEP", payload: 1 })}
                  className="tw-mt-4 tw-mb-2 tw-bg-[#E7075B] button-modif"
                >
                  {t("tarifcard:modif")}
                </button>
              </div>
              <div
                className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
                style={{
                  top: -15,
                  backgroundColor: "#fff",
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
                  Camping & Extra
                </span>
              </div>
            </div>
          )}

          {(reservation.dates[0].service_fee ||
            reservation.dates[0].battery) && (
            <div
              className="tw-ring-[0.5px] my-2 tw-w-[95%] fieldset position-relative tw-self-center tw-flex-wrap tw-min-h-[70px] tw-gap-y-[10px] tw-py-[10px] tw-justify-center"
              style={{
                borderColor: "#9A9FA5",
              }}
            >
              {reservation.dates.map((date, id) => (
                <div key={id}>
                  <div className="d-flex tw-self-center tw-flex-wrap align-items-center justify-content-between md:tw-px-[2.5%] tw-px-[5px] tw-w-full tarif-container tw-my-3">
                    <div className="tw-w-full">
                      <span className="tw-font-['Poppins'] tw-font-semibold tw-text-[10px]">
                        {dayjs(date.date)
                          .format("dddd DD MMMM YYYY")
                          .toUpperCase()}
                      </span>
                      <div className="tw-w-full tw-flex tw-flex-col tw-items-end tw-justify-end">
                        {date.battery &&
                          reservation?.dates[0].battery?.map((batt) => (
                            <p className=" tw-font-['Poppins'] tw-font-semibold tw-text-[12px] tw-ml-2 ">
                              • 1 x {batt.name} = {batt.price + " €"}
                            </p>
                          ))}
                        {date.service_fee && (
                          <div>
                            <p className="tw-font-['Poppins'] tw-font-semibold tw-text-[12px] tw-ml-2">
                              • 1 x Locker ({date?.locker_type.toUpperCase()}) ={" "}
                              {date?.service_fee + " €"}
                            </p>
                          </div>
                        )}
                      </div>

                      <button
                        onClick={() =>
                          dispatch({ type: "SET_STEP", payload: 1 })
                        }
                        className="tw-mt-4 tw-mb-2 tw-bg-[#E7075B] button-modif"
                      >
                        {t("tarifcard:modif")}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
                style={{
                  top: -15,
                  backgroundColor: "#fff",
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
                  Lockers & battery
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="tw-ring-1 tw-px-1 md:tw-px-3 tw-py-6 tw-ring-slate-200 tw-flex tw-flex-col lg:tw-w-[40%] lg:tw-mt-0 tw-mt-12 tw-w-[100%] tw-bg-white tw-rounded-lg tw-max-h-[390px] tw-justify-center tw-overflow-scroll ">
          <div className=" tw-w-full tw-flex-row tw-flex tw-justify-between">
            <span className=" tw-font-['Poppins'] tw-font-[500] tw-text-[12px] md:tw-text-[13.5px] tw-text-slate-500 ">
              {t("pay:soustotal")}
            </span>
            <span className=" tw-font-semibold tw-text-[13px] md:tw-text-[15px] tw-text-slate-500">
              {total.toFixed(2)} €
            </span>
          </div>

          <div className=" tw-w-full tw-flex-row tw-flex tw-justify-between tw-mt-2">
            <span className=" tw-font-['Poppins'] tw-font-[500] tw-text-[12px] md:tw-text-[13.5px] tw-text-slate-500 ">
              {t("pay:fraiservice")}
            </span>
            <span className=" tw-font-semibold tw-text-[13px] md:tw-text-[15px] tw-text-slate-500">
              0.80 €
            </span>
          </div>

          {promocodeload.data ? (
            <div className=" tw-w-full tw-flex-row tw-flex tw-justify-between tw-mt-2">
              <span className=" tw-font-['Poppins'] tw-font-[500] tw-text-[12px] md:tw-text-[13.5px] tw-text-slate-500 ">
                {t("pay:reduction")} (-{promocodeload.data.discount}%)
              </span>
              <span className=" tw-font-semibold tw-text-[13px] md:tw-text-[15px] tw-text-slate-500">
                {" "}
                {((total * promocodeload.data.discount) / 100).toFixed(
                  2,
                )} €{" "}
              </span>
            </div>
          ) : (
            <div className=" tw-w-full tw-flex-row tw-flex tw-justify-between tw-mt-2">
              <span className=" tw-font-['Poppins'] tw-font-[500] tw-text-[12px] md:tw-text-[13.5px] tw-text-slate-500 ">
                {t("pay:questionpromo")}
              </span>
            </div>
          )}

          <div className=" tw-w-full tw-flex-row tw-flex tw-justify-between tw-mt-2">
            {!promocodeload.data && (
              <>
                <input
                  type="text"
                  value={promocode}
                  onChange={(evt) => setPromocode(evt.currentTarget.value)}
                  className="tw-py-2 tw-text-sm tw-h-[40px] tw-rounded-l-md tw-ring-1 tw-ring-slate-200 tw-px-2 tw-w-full focus:tw-outline-none tw-bg-white tw-text-gray-900"
                  placeholder={t("pay:cdpromo")}
                />
                <button
                  onClick={() => handlePromoCode(promocode)}
                  className=" tw-h-[40px] tw-px-[10px] tw-rounded-r-md tw-bg-[#E7075B] tw-text-white tw-text-[14px] "
                >
                  {promocodeload.loading ? (
                    <CircularProgress size={30} sx={{ color: "#fff" }} />
                  ) : (
                    t("pay:verif")
                  )}
                </button>
              </>
            )}
          </div>
          {promocodeload.error && (
            <div className="w-full tw-mt-2">
              <span
                className={
                  " tw-text-[12px] " +
                  (promocodeload.error
                    ? "tw-text-red-500"
                    : "tw-text-green-500")
                }
              >
                {promocodeload.message}
              </span>
            </div>
          )}

          <hr className=" tw-mt-2" />

          <div className=" tw-w-full tw-flex-row tw-flex tw-justify-between tw-mt-2">
            <span className=" tw-font-['Poppins'] tw-font-[500] tw-text-[12px] md:tw-text-[13.5px] tw-text-slate-500 ">
              {t("pay:total")}
            </span>
            <span className=" tw-font-semibold tw-text-[13px] md:tw-text-[15px] tw-text-slate-500">
              {amount.toFixed(2)} €
            </span>
          </div>

          <div className=" tw-w-full tw-flex tw-mt-[10px]">
            <input
              type="checkbox"
              onChange={(e) => setTerms(e.target.checked)}
              className=" tw-w-[23px] tw-h-[23px] "
            />

            <span className=" tw-text-[12px] tw-ml-3 ">
              {t("pay:accept")}{" "}
              <a
                target="_blank"
                href={baseUrl + "/cgu"}
                className=" tw-font-bold hover:tw-text-[#E7075B]"
                rel="noreferrer"
              >
                {t("pay:condition1")}{" "}
              </a>{" "}
              {t("pay:and")}{" "}
              <a
                target="_blank"
                href={baseUrl + "/cgv"}
                className=" tw-font-bold hover:tw-text-[#E7075B] "
                rel="noreferrer"
              >
                {t("pay:condition2")}{" "}
              </a>
            </span>
          </div>
          {haveTopay ? (
            <button
              disabled={!term}
              className={
                "tw-font-medium tw-text-white tw-mt-6 tw-rounded-lg md:tw-w-[90%] tw-w-[95%] tw-py-[8px] tw-text-[14px] tw-self-center hover:shadow-md focus:ring-2 " +
                (!term ? "tw-bg-[#F17CAF]" : "tw-bg-[#E7075B]")
              }
              onClick={() => handlePayment()}
            >
              {loading ? (
                <CircularProgress size={30} sx={{ color: "#fff" }} />
              ) : (
                t("pay:success")
              )}
            </button>
          ) : (
            <button
              disabled={!term}
              className={
                "tw-font-medium tw-text-white tw-mt-6 tw-rounded-lg md:tw-w-[90%] tw-w-[95%] tw-py-[8px] tw-text-[14px] tw-self-center hover:shadow-md focus:ring-2 " +
                (!term ? "tw-bg-[#F17CAF]" : "tw-bg-[#E7075B]")
              }
              onClick={() => handleSuccess()}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "#fff" }} />
              ) : (
                t("pay:success")
              )}
            </button>
          )}
        </div>
      </div>

      {term && (
        <div
          className={
            " tw-w-[95%] tw-mt-12 tw-flex tw-flex-col tw-rounded-lg " +
            (clientSecret
              ? "tw-px-3 tw-py-4 tw-ring-slate-200 tw-bg-white tw-ring-1 "
              : "")
          }
        >
          {clientSecret && (
            <div>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm event={event} />
              </Elements>
              <div className="ident-div tw-w-full md:tw-mt-0 tw-mt-8 d-flex flex-row align-items-center tw-justify-center">
                <div className="tw-w-[300px] ">
                  <img
                    src={require("../../assets/img/stripe-badge-grey.png")}
                    alt="playstore"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Paiement;
