import React, { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import CircularProgress from "@mui/material/CircularProgress";

import { getCgu } from "../../redux/reducers/legalText";
import { useDispatch, useSelector } from "react-redux";
import "./mentionLegal.css";

const Cgu = () => {
  const load = useSelector((state) => state.params.loadTerms);
  const cgu = useSelector((state) => state.termsReducer.cgu);
  const lng = useSelector((state) => state.params.langage);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCgu());
  }, [dispatch]);

  return (
    <div className="w-100 ">
      <div
        style={{ height: 300 }}
        className="container-fluid w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center"
      >
        <span style={{ width: "70%" }} className="job-details-title">
          {t("cgu:titrcgu").toUpperCase()}
        </span>
      </div>

      <div className="container">
        <div style={{ width: "100%" }} className="mt-3 ">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {/* {breadcrumbs} */}
            <span className="bread">
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                }}
                id="bread"
              >
                {t("rgpd:home")}
              </NavLink>
            </span>
            <span>{t("cgu:titrcgu")}</span>
          </Breadcrumbs>
        </div>

        {load ? (
          <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
            <CircularProgress sx={{ color: "#E7075B" }} />
          </div>
        ) : (
          <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
            <div style={{ width: "90%" }} className="w-100">
              {lng?.slice(0, 2) === "fr" || lng?.slice(0, 2) === "FR" ? (
                <div
                  className="mentionDiv"
                  dangerouslySetInnerHTML={{
                    __html: cgu.cgu,
                  }}
                />
              ) : (
                <div
                  className='"mentionDiv"'
                  dangerouslySetInnerHTML={{
                    __html: cgu.english_cgu,
                  }}
                />
              )}
              {/* <Fragment>
                                    {parse( (lng?.slice(0, 2) === 'fr') || (lng?.slice(0, 2) === 'FR') ? cgu.cgu : cgu.english_cgu )}
                                </Fragment> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cgu;
