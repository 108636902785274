import React, { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AspectRatio } from "react-aspect-ratio";
import CircularProgress from "@mui/material/CircularProgress";

import { getTestimonies } from "../../redux/reducers/testimonies";
import { useDispatch, useSelector } from "react-redux";

const OnParleDeNous = () => {
  const load = useSelector((state) => state.params.loadTestimonies);
  const testimonies = useSelector(
    (state) => state.testimoniesReducer.allTestimonies,
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTestimonies());
  }, []);

  if (load) {
    return (
      <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
        <CircularProgress sx={{ color: "#E7075B" }} />
      </div>
    );
  }

  return (
    <div className="w-100 ">
      <div
        style={{ height: 300 }}
        className="container-fluid w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center"
      >
        <span style={{ width: "70%" }} className="job-details-title">
          {t("aboutus:titrabout").toUpperCase()}
        </span>
      </div>

      <div className="container">
        <div style={{ width: "100%" }} className="mt-3 ">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {/* {breadcrumbs} */}
            <span className="bread">
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                }}
                id="bread"
              >
                {t("rgpd:home")}
              </NavLink>
            </span>
            <span>{t("aboutus:titrabout")}</span>
          </Breadcrumbs>
        </div>

        <div className="w-100 tw-mt-3 tw-mb-10 d-flex tw-flex-wrap align-items-center ">
          {testimonies.length > 0 &&
            testimonies.map((index) => (
              <a
                target="_blank"
                href={index.link}
                rel="noreferrer"
                key={index.id}
                className=" tw-cursor-pointer md:tw-w-[30%] tw-shadow-sm tw-rounded-md tw-overflow-hidden tw-w-[95%] tw-justify-center tw-items-center tw-mt-[35px] tw-mx-[1.15%] "
              >
                <AspectRatio
                  ratio="1/1"
                  className=""
                  style={{ backgroundColor: "rgb(210,210,210)" }}
                >
                  <img
                    src={
                      `${process.env.REACT_APP_PUBLIC_URL}/cms/file/` +
                      index.image
                    }
                    className="object-cover"
                  />
                </AspectRatio>

                <div className=" tw-w-[100%] tw-flex tw-flex-col tw-h-full tw-py-3 tw-bg-white ">
                  <span className=" tw-font-semibold  tw-px-3">
                    {index.name}
                  </span>
                </div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OnParleDeNous;
