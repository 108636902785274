const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
// const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

export const EventsApi = {
  async getAllEvents() {
    const response = await fetch(baseUrl + "/events", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    if (response.ok) return data;

    return null;
  },

  async getEvent(id) {
    const response = await fetch(baseUrl + "/events/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    if (response.ok) return data;

    return null;
  },
};
