const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
const token = `${process.env.REACT_APP_TOKEN}`;
// const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

export const ReservationApi = {
  async createReservation(reservation) {
    let today = new Date();
    today = today.toISOString().split("T")[0];

    const new_reservation = {
      event_id: reservation.event_id,
      reservation_date: today,
      container_serial_nb: reservation.container_serial_nb,
      container_details: reservation.container_details,
      services: reservation.services,
      // stringify each date
      dates: reservation.dates.map((date) => JSON.stringify(date)),
      pass: reservation.pass || false,
    };

    const response = await fetch(baseUrl + `/reservations`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(new_reservation),
    });
    const data = await response.json();

    if (response.ok) return data;

    return null;
  },

  async deleteReservation(uuid) {
    const response = await fetch(baseUrl + `/reservations/${uuid}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.text();
  },

  async confirmPayReservation(new_reservation) {
    await fetch(baseUrl + `/reservations/${new_reservation.uid}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(new_reservation),
    })
      .then((response) => response.json())
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return null;
      });
  },
};
