import React, { useState } from "react";
import "./eventGroup.css";
import EventCard from "./EventCard";
import { Collapse } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";

const dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const EventGroup = ({ dateRange, events }) => {
  const [collapse, setCollapse] = useState(true);
  const toggle = () => setCollapse(!collapse);

  return (
    <div className="event_group">
      <div className="event_group_title tw-flex tw-items-center tw-mb-2">
        {/* <h3 style={{fontSize: 25, fontWeight: 'bold'}} >{dateRange}</h3> */}
        <h3 style={{ fontSize: 25, fontWeight: "bold" }}>
          {dayjs(events?.[0]?.start_date).utc().format("MMMM YYYY")}
        </h3>
        <FaChevronDown onClick={toggle} size={12} />
      </div>

      <hr id="divider_events" />

      <Collapse isOpen={collapse}>
        {/* <div className=" tw-flex tw-flex-row tw-flex-wrap sm:tw-justify-start tw-justify-center"> */}
        <div className=" tw-flex tw-flex-row tw-flex-wrap sm:tw-justify-start tw-justify-center ">
          {events.map((e, index) => (
            <EventCard key={index} evenement={e} />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default EventGroup;
