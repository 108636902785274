import { useSelector, useDispatch } from "react-redux";
import "./stepper.css";
import chartIcon from "../../assets/icons/chart.svg";
import dateIcon from "../../assets/icons/date.svg";
import identificationIcont from "../../assets/icons/identification.svg";
import creditcardIcon from "../../assets/icons/creditcard.svg";
import recapIcon from "../../assets/icons/recap.svg";
import elipseIcon from "../../assets/icons/elipse_.svg";
import elipseFullIcon from "../../assets/icons/elipseFull.svg";
import chartFullIcon from "../../assets/icons/chartFull.svg";
import identificationFullIcon from "../../assets/icons/identificationFull.svg";
import creditcardFullIcon from "../../assets/icons/creditCardFull.svg";
import recapFullIcon from "../../assets/icons/recapFull.svg";
import datefull from "../../assets/icons/datefull.png";
import { useTranslation } from "react-i18next";

const icons = [
  recapIcon,
  datefull,
  chartIcon,
  recapIcon,
  identificationIcont,
  creditcardIcon,
  recapIcon,
];

const fullIcone = [
  recapFullIcon,
  dateIcon,
  recapFullIcon,
  chartFullIcon,
  identificationFullIcon,
  creditcardFullIcon,
  recapFullIcon,
];

const Stepper = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentStep = useSelector((state) => state.stepper.step);
  const reservation =
    useSelector((state) => state.reservationReducer.reservationInProgres) || [];

  const steps = [
    { name: t("stepper:date"), position: 0 },
    { name: t("stepper:service"), position: 1 },
    { name: t("stepper:sizeandpri"), position: 2 },
    { name: t("stepper:service+"), position: 3 },
    { name: t("stepper:authen"), position: 4 },
    { name: t("stepper:form"), position: 5 },
    { name: t("stepper:pay"), position: 6 },
  ];

  const filteredSteps = steps.filter((step, index) => {
    if (step.position === 2 && !reservation?.services?.includes("locker")) {
      return false;
    }
    if (
      step.position === 3 &&
      !(
        reservation?.services?.includes("camping") ||
        reservation?.services?.includes("battery") ||
        reservation?.services?.includes("green_cab")
      )
    ) {
      return false;
    }
    return true;
  });

  const navigateTo = (step) => {
    dispatch({ type: "SET_STEP", payload: step });
  };

  return (
    <div className="constainer d-flex stepper-div">
      {filteredSteps.map((step, index) => (
        <div key={index} className="d-flex flex-column step-div">
          <div
            onClick={() =>
              currentStep >= step.position ? navigateTo(step.position) : null
            }
            className="stepper-icon-box d-flex gap-2 align-items-end"
          >
            <img
              src={
                currentStep >= step.position
                  ? fullIcone[step.position]
                  : icons[step.position]
              }
              alt={step.name}
            />
            {currentStep >= step.position ? (
              <p className="d-none d-md-flex tw-text-[13px]">
                <a onClick={() => navigateTo(index)}>{step.name}</a>
              </p>
            ) : (
              <p className="d-none d-md-flex tw-text-[13px]">{step.name}</p>
            )}
          </div>

          <img
            src={currentStep >= step.position ? elipseFullIcon : elipseIcon}
            alt="elipse"
            className="elipse-div"
          />
        </div>
      ))}
    </div>
  );
};

export default Stepper;
