import axios from "axios";
import produce from "immer";

const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/slide`;

const GET_SLIDES = "GET_SLIDES";

export const getSlides = () => async (dispatch) => {
  dispatch({ type: "LOAD_SLIDES", payload: true });
  const response = await axios.get(url);
  const slideData = response.data;

  dispatch({
    type: GET_SLIDES,
    payload: slideData,
  });
  dispatch({ type: "LOAD_SLIDES", payload: false });
};

const slideappState = {
  allslides: [],
  allLinks: {},
};

const slideReducer = (state = slideappState, action) => {
  let nextState;

  switch (action.type) {
    case GET_SLIDES:
      nextState = produce(state, (draftState) => {
        draftState.allslides = action.payload.slides;
        draftState.allLinks = action.payload.links;
      });

      return nextState || state;

    default:
      return state;
  }
};

export default slideReducer;
