import "./search.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filterEvents } from "../../redux/reducers/events";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const Search = () => {
  const { t } = useTranslation();

  const events = useSelector((state) => state.eventReducer.allEvents) || [];
  const filtreGroupe =
    useSelector((state) => state.eventReducer.filtreGroupe) || [];

  const dispatch = useDispatch();
  const keyworld_group1 = [
    t("search:FesCon"),
    t("search:ParMus"),
    t("search:PlaPar"),
    t("search:EveCul"),
    t("search:FoiSal"),
    t("search:ParZoo"),
    t("search:EveSstad"),
    t("search:SiteCenvi"),
  ];

  // event by type
  // filter event by type
  let filteredGroup = [...filtreGroupe];

  useEffect(() => {
    dispatch(filterEvents(events, filteredGroup));
  }, [events]);

  const eventTypes = [
    { value: "FC", label: t("search:FesCon") },
    { value: "FS", label: t("search:ParMus") },
    { value: "PE", label: t("search:FoiSal") },
    { value: "ES", label: t("search:EveCul") },
    { value: "EC", label: t("search:PlaPar") },
    { value: "PA", label: t("search:ParZoo") },
    { value: "PZ", label: t("search:EveSstad") },
    { value: "ST", label: t("search:SiteCenvi") },
  ];

  const filterby = (type) => {
    let typeValue = eventTypes.find((v) => v.label === type).value;

    // if type is not in filteredGroup, add it.
    if (!filteredGroup.includes(typeValue)) {
      filteredGroup.push(typeValue);
    } else {
      // if type is in filteredGroup, remove it.
      const index = filteredGroup.indexOf(typeValue);
      filteredGroup.splice(index, 1);
    }

    dispatch(filterEvents(events, filteredGroup));
  };

  return (
    <div
      id="events"
      style={{ scrollMarginTop: 120, scrollMarginBottom: 120 }}
      className="search"
    >
      <div className="event_group_title d-flex">
        <h3 style={{ fontSize: 20, fontWeight: "bold", marginBottom: 5 }}>
          {t("search:title")}
        </h3>
      </div>
      <hr id="divider_events" />
      <Form className="mt-3">
        <FormGroup
          style={{ alignSelf: "center" }}
          className="tw-flex tw-flex-row tw-flex-wrap "
        >
          {keyworld_group1.map((item, index) => {
            return (
              <FormGroup
                check
                inline
                key={index}
                className="lg:tw-w-[20%] sm:tw-w-[20%] xs:tw-min-w-[40%] sm:tw-mx-[2.5%] tw-mx-[5%] tw-flex tw-flex-wrap"
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={() => filterby(item)}
                    checked={filtreGroupe.includes(
                      eventTypes.find((v) => v.label === item).value,
                    )}
                  />{" "}
                  {item}
                </Label>
              </FormGroup>
            );
          })}
        </FormGroup>
      </Form>
    </div>
  );
};

export default Search;
