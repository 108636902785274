import produce from "immer";

const paramsappState = {
  loadEvents: true,
  loadSuccess: false,
  loadSlides: false,
  loadJobs: false,
  loadFaqs: false,
  loadTestimonies: false,
  loadPartners: false,
  loadTerms: false,
  loadLocker: {
    loading: false,
    error: false,
  },
  notFound: false,
  backDrop: false,
  dialog: {
    active: false,
    view: null,
    value: null,
  },
  langage: null,
};

function paramsappReducer(state = paramsappState, action) {
  let nextState;

  switch (action.type) {
    case "LOAD_EVENTS":
      nextState = produce(state, (draftState) => {
        draftState.loadEvents = action.payload;
      });

      return nextState || state;

    case "LOAD_SLIDES":
      nextState = produce(state, (draftState) => {
        draftState.loadSlides = action.payload;
      });

      return nextState || state;

    case "LOAD_JOBS":
      nextState = produce(state, (draftState) => {
        draftState.loadJobs = action.payload;
      });

      return nextState || state;

    case "LOAD_PARTNERS":
      nextState = produce(state, (draftState) => {
        draftState.loadPartners = action.payload;
      });

      return nextState || state;

    case "LOAD_FAQS":
      nextState = produce(state, (draftState) => {
        draftState.loadFaqs = action.payload;
      });

      return nextState || state;

    case "LOAD_SUCCESS":
      nextState = produce(state, (draftState) => {
        draftState.loadSuccess = action.payload;
      });

      return nextState || state;

    case "LOAD_TESTIMONIES":
      nextState = produce(state, (draftState) => {
        draftState.loadTestimonies = action.payload;
      });

      return nextState || state;

    case "LOAD_TERMS":
      nextState = produce(state, (draftState) => {
        draftState.loadTerms = action.payload;
      });

      return nextState || state;

    case "LOAD_LOCKER":
      nextState = produce(state, (draftState) => {
        draftState.loadLocker = action.payload;
      });

      return nextState || state;

    case "NOT_FOUND":
      nextState = produce(state, (draftState) => {
        draftState.notFound = action.payload;
      });

      return nextState || state;

    case "SET_BACKDROP":
      nextState = produce(state, (draftState) => {
        draftState.backDrop = action.payload;
      });

      return nextState || state;

    case "SET_DIALOG":
      nextState = produce(state, (draftState) => {
        draftState.dialog = action.payload;
      });

      return nextState || state;

    case "SET_LANGUAGE":
      nextState = produce(state, (draftState) => {
        draftState.langage = action.payload;
      });

      return nextState || state;

    default:
      return state;
  }
}

export default paramsappReducer;
