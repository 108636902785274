import axios from "axios";

const baseUrl = `${process.env.REACT_APP_ROOT_API}`;
const api = `${process.env.REACT_APP_PUBLIC_URL}`;

//POST REQUEST

export function goToPay(data) {
  return axios.post(`${baseUrl}/init-payment`, data);
}

//AFTER SUCCEEDED PAYMENT PROTOCOL

export function getSuccessPaymentInfo(id) {
  return axios.get(`${api}/reservations/orderCode/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

export function getEvents(id) {
  return axios.get(`${api}/events/${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}
