/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import produce from "immer";
import { useDispatch } from "react-redux";
import { Check } from "@mui/icons-material";
import solo from "../../assets/icons/s+/kit solo.png";
import duo from "../../assets/icons/s+/Kit duo.png";
import quatro from "../../assets/icons/s+/kit quatro.png";
import galcie from "../../assets/icons/s+/Glaciere.png";
import oreil from "../../assets/icons/s+/oreiller.png";
import lamp from "../../assets/icons/s+/torche.png";
import chaise from "../../assets/icons/s+/chaise.png";
import tabou from "../../assets/icons/s+/table.png";

export default function KitCamping({ event }) {
  const { t } = useTranslation();

  const reservation =
    useSelector((state) => state.reservationReducer.reservationInProgres) || [];

  const [selection, setSelection] = useState(
    reservation?.dates[0]?.kit_camping?.map((items) => {
      return items?.id;
    }) || [],
  );
  const [extraSelect, setExtraSelect] = useState(
    reservation?.dates[0]?.extra?.map((items) => {
      return items?.id;
    }) || [],
  );
  const dispatch = useDispatch();

  const kit = [
    {
      id: "ks1",
      name: "KIT SOLO",
      price: 89,
      icon: solo,
      stock: 0,
    },
    {
      id: "ks2",
      name: "KIT DUO",
      price: 159,
      icon: duo,
      stock: 0,
    },
    {
      id: "ks3",
      name: "KIT QUATRO",
      price: 299,
      icon: quatro,
      stock: 0,
    },
  ];
  if (event?.servicesStock?.camping) {
    for (let i = 0; i < kit.length; i++) {
      if (event.servicesStock.camping.find((item) => item.key === kit[i].id)) {
        const exist = event.servicesStock.camping.find(
          (item) => item.key === kit[i].id,
        );

        kit[i].stock = exist.availableStock;
      } else {
        kit[i].stock = 0;
      }
    }
  }
  const extras = [
    {
      id: "et1",
      name: "GLACIERE 30 LITRES",
      price: 30,
      icon: galcie,
      stock: 0,
    },
    {
      id: "et2",
      name: "OREILLER 40/40 CM",
      price: 18,
      icon: oreil,
      stock: 0,
    },
    {
      id: "et3",
      name: "LAMPE TORCHE",
      price: 5,
      icon: lamp,
      stock: 0,
    },
    {
      id: "et4",
      name: "CHAISE PLIANTE",
      price: 20,
      icon: chaise,
      stock: 0,
    },
    {
      id: "et5",
      name: "TABOURET",
      price: 10,
      icon: tabou,
      stock: 0,
    },
  ];

  if (event?.servicesStock?.extra) {
    for (let i = 0; i < extras.length; i++) {
      if (event.servicesStock.extra.find((item) => item.key === extras[i].id)) {
        const exist = event.servicesStock.extra.find(
          (item) => item.key === extras[i].id,
        );

        extras[i].stock = exist.availableStock;
      } else {
        extras[i].stock = 0;
      }
    }
  }

  const modifiedReservation = () => {
    const updatedReservation = produce(reservation, (draft) => {
      console.log("selection", selection);
      if (!selection) {
        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.kit_camping;
          });
        }
      }
    });
    return updatedReservation;
  };

  const handleSelection = (id) => {
    const copy = selection.includes(id)
      ? selection.filter((extraId) => extraId !== id)
      : [...selection, id];

    setSelection(copy);

    const payload = copy.map((kitId) => {
      const elements = kit.find((data) => data.id === kitId);
      return { id: elements.id, name: elements.name, price: elements.price };
    });

    if (!payload.length) {
      const updatedReservation = produce(reservation, (draft) => {
        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.kit_camping;
          });
        }
      });

      dispatch({ type: "UPDATE_RESERVATION", payload: updatedReservation });
    } else {
      dispatch({
        type: "SET_KIT_CAMPING",
        payload,
      });
    }
  };

  const handleExtraSelection = (id) => {
    const copy = extraSelect.includes(id)
      ? extraSelect.filter((extraId) => extraId !== id)
      : [...extraSelect, id];

    setExtraSelect(copy);

    const payload = copy.map((extraId) => {
      const elements = extras.find((data) => data.id === extraId);
      return { id: elements.id, name: elements.name, price: elements.price };
    });

    if (!payload.length) {
      const updatedReservation = produce(reservation, (draft) => {
        if (Array.isArray(draft.dates)) {
          draft.dates.forEach((date) => {
            delete date.extra;
          });
        }
      });

      dispatch({ type: "UPDATE_RESERVATION", payload: updatedReservation });
    } else {
      dispatch({
        type: "SET_EXTRA",
        payload,
      });
    }
  };

  return (
    <div className="w-100 tw-flex tw-flex-col">
      <div
        className="w-100 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
        style={{
          borderColor: "#9A9FA5",
        }}
      >
        <div className="tw-w-[95%] tw-mb-2 tw-flex tw-self-center tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
          Une location pour la durée totale de l'événement selon ses horaires à
          retirer sur place à notre stand.
        </div>
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-my-8 lg:tw-px-16 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-8">
          {kit?.map((data, i) => {
            const isSelected = selection?.includes(data?.id);
            if (data?.stock > 0)
              return (
                <div key={i} className="tw-w-full tw-flex tw-flex-col tw-gap-2">
                  <div
                    style={{
                      border: isSelected
                        ? "1px solid #E7075B"
                        : "1px solid #ddd",
                      color: isSelected ? "green" : "#E7075B",
                    }}
                    className="tw-w-full tw-py-4 tw-text-center tw-border tw-flex tw-flex-col tw-items-center tw-rounded-xl"
                  >
                    <div className="tw-w-full tw-text-[24px] md:tw-text-[20px] lg:tw-text-[18px] tw-text-[#000000] tw-font-['Poppins'] tw-font-medium">
                      {data?.name}
                    </div>
                    <div className="tw-my-2 tw-rounded-[20px]">
                      {" "}
                      <img
                        src={data?.icon}
                        className="tw-rounded-[20px]"
                        height="75px"
                        width="150px"
                      />
                    </div>
                    {i === 0 && (
                      <div className="tw-flex tw-flex-col tw-items-start">
                        <div className="tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 tente individuelle
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 sac de couchage
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 matelas gonflable
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 lampe torche
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 pompe
                          </h3>
                        </div>
                      </div>
                    )}

                    {i === 1 && (
                      <div className="tw-flex tw-flex-col tw-items-start">
                        <div className="tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 tente pour 2 pers.
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            2 sacs de couchages
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            2 matelas gonflables
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            2 lampes torches
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 pompe
                          </h3>
                        </div>
                      </div>
                    )}

                    {i === 2 && (
                      <div className="tw-flex tw-flex-col tw-items-start">
                        <div className="tw-flex tw-flex-row gap-2 tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 tente pour 4 pers.
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            4 sacs de couchages
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            4 matelas gonflables
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            4 lampes torches
                          </h3>
                        </div>
                        <div className="border tw-my-1 tw-opacity-[10]"></div>
                        <div className="tw-flex gap-2 tw-flex-row tw-items-center tw-justify-center">
                          {" "}
                          <Check color="#E7075B" />
                          <h3
                            style={{ color: "#000" }}
                            className="tw-text-[14px]"
                          >
                            1 pompe
                          </h3>
                        </div>
                      </div>
                    )}
                    <span className="title-forfait mt-2 tw-text-[12px] sm:tw-text-[14px] text-black md:tw-text-[28px]">
                      {data.price} €*
                    </span>
                  </div>
                  <button
                    onClick={() => handleSelection(data?.id)}
                    style={{
                      background: isSelected ? "#E7075B" : "#fff",
                      border: isSelected
                        ? "1px solid #fff"
                        : "1px solid #E7075B",
                      color: isSelected ? "#fff" : "#E7075B",
                    }}
                    className="tw-w-full tw-p-3 tw-rounded-xl"
                  >
                    {isSelected
                      ? t("services:btn_start")
                      : t("services:btn_clicked")}
                  </button>
                </div>
              );
          })}
        </div>
        <div className="tw-w-[95%] tw-mb-2 tw-flex tw-flex-row tw-items-end tw-justify-end tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
          <h1>
            {" "}
            Une caution sera exigée lors du retrait avec les moyens de paiement
            acceptés sur l'événement.
          </h1>
        </div>
        <div
          className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
          style={{
            top: -15,
            backgroundColor: "#FFF8F8",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
            CHOISISSEZ VOTRE KIT CAMPING
          </span>
        </div>
      </div>

      {/**EXTRAS */}

      <div
        className="w-100 tw-mt-16 fieldset position-relative tw-flex tw-justify-center tw-flex-col"
        style={{
          borderColor: "#9A9FA5",
        }}
      >
        <div className="tw-w-[95%] tw-mb-2 tw-flex tw-self-center tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
          Une location pour la durée totale de l'événement selon les horaires.
        </div>
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-my-8 lg:tw-px-16 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-5 tw-gap-8">
          {extras?.map((data, i) => {
            const isSelected = extraSelect?.includes(data?.id);
            if (data?.stock > 0)
              return (
                <div key={i} className="tw-w-full tw-flex tw-flex-col tw-gap-2">
                  <div
                    style={{
                      border: isSelected
                        ? "1px solid #E7075B"
                        : "1px solid #ddd",
                      color: isSelected ? "green" : "#E7075B",
                    }}
                    className="tw-w-full tw-py-4 tw-text-center tw-border tw-flex tw-flex-col tw-items-center tw-rounded-xl"
                  >
                    <div className="tw-w-full tw-text-[24px] md:tw-text-[20px] lg:tw-text-[18px] tw-text-[#000000] tw-font-['Poppins'] tw-font-medium">
                      {data?.name}
                    </div>
                    <div className="tw-my-2 tw-rounded-[20px]">
                      {" "}
                      <img
                        src={data?.icon}
                        className="tw-rounded-[20px]"
                        height="75px"
                        width="150px"
                      />
                    </div>

                    <span className="title-forfait mt-2 tw-text-[12px] sm:tw-text-[14px] text-black md:tw-text-[28px]">
                      {data.price} €
                    </span>
                  </div>
                  <button
                    onClick={() => handleExtraSelection(data?.id)}
                    style={{
                      background: isSelected ? "#E7075B" : "#fff",
                      border: isSelected
                        ? "1px solid #fff"
                        : "1px solid #E7075B",
                      color: isSelected ? "#fff" : "#E7075B",
                    }}
                    className="tw-w-full tw-p-3 tw-rounded-xl"
                  >
                    {isSelected
                      ? t("services:btn_start")
                      : t("services:btn_clicked")}
                  </button>
                </div>
              );
          })}
        </div>
        <div className="tw-w-[95%] tw-mb-2 tw-flex tw-flex-row tw-items-end tw-justify-end tw-font-['Poppins'] tw-font-medium tw-text-[13px] ">
          <h1>
            {" "}
            Une caution sera exigée lors du retrait avec les moyens de paiement
            acceptés sur l'événement.
          </h1>
        </div>
        <div
          className="position-absolute px-3 d-flex tw-mx-4 tw-self-start"
          style={{
            top: -15,
            backgroundColor: "#FFF8F8",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
            NOS EXTRAS
          </span>
        </div>
      </div>
    </div>
  );
}
