import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

import "./qrcodePage.css";

const QrcodePage = () => {
  const { t } = useTranslation();

  const [qrcode, setQrcode] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const url = `${process.env.REACT_APP_PUBLIC_URL}/reservations/frontqrcode/${id}`;

        const res = await axios.get(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        });

        setQrcode(res.data?.qrcodeLink);
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
        NotificationManager.error(t("pagesuccess:qrcodeNofund"), "Oups", 5000);
      }
    }

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
        <CircularProgress sx={{ color: "#E7075B" }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
        <h1>{t("pagesuccess:qrcodeNofund")}</h1>
      </div>
    );
  }

  return (
    <div className="container-fluid tw-h-400 d-flex flex-column justify-content-center align-items-center image-container">
      <img src={qrcode} />
    </div>
  );
};

export default QrcodePage;
