import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const NotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "NOT_FOUND", payload: true });
    return () => {
      dispatch({ type: "NOT_FOUND", payload: false });
    };
  }, []);

  return (
    <div className="tw-h-screen tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-[#FFF8F8]">
      <h1 className="tw-text-9xl tw-font-extrabold tw-text-slate-500 tw-tracking-widest">
        404
      </h1>
      <div className="tw-bg-[#E7075B] tw-text-white tw-px-2 tw-text-sm tw-rounded tw-rotate-12 tw-absolute">
        Page Not Found
      </div>
      {/* <button class="mt-5">
            <a
                class="relative inline-block text-sm font-medium text-[#FF6A3D] group active:text-orange-500 focus:outline-none focus:ring"
            >
                <span
                class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#FF6A3D] group-hover:translate-y-0 group-hover:translate-x-0"
                ></span>

                <span class="relative block px-8 py-3 bg-[#1A2238] border border-current">
                <router-link to="/">Go Home</router-link>
                </span>
            </a>
            </button> */}
    </div>
  );
};

export default NotFound;
