import React, { useState, useEffect } from "react";
import "./navigation.css";
import logo from "../assets/img/logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, batch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoCloseCircleOutline } from "react-icons/io5";
import { NavLink } from "reactstrap";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";

const dayjs = require("dayjs");
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "red",
  },
}));

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const panier = useSelector((state) => state.panierReducer.panier);
  const notFound = useSelector((state) => state.params.notFound);
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );
  const lng = useSelector((state) => state.params.langage);

  const links = useSelector((state) => state.sliderReducer.allLinks);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    panier.length > 0 && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleDeleteArticle = (data) => {
    dispatch({
      type: "SET_DIALOG",
      payload: { active: true, view: "deletePa", value: data },
    });
  };

  const onlyWidth = useWindowWidth();

  return (
    <div className="tw-sticky tw-top-0 tw-bg-[#FFF8F8] tw-z-[999]">
      {!notFound && (
        <nav className="container tw-py-2 d-flex align-items-center justify-content-between">
          <a href="#top" className="tw-w-[70px] tw-h-[70px]">
            <div className="logo tw-w-full tw-h-full">
              <img src={logo} alt="logo" />
            </div>
          </a>

          <div className={`navbar ${isOpen ? "showMenu" : ""}`}>
            <ul className="d-flex navLink">
              <li className="tw-mx-4">
                <NavLink href="#how_it_works" onClick={toggle}>
                  {t("navigation:howitwork")}
                </NavLink>
              </li>
              <li className="tw-mx-4">
                <NavLink href="#events" onClick={toggle}>
                  {t("navigation:Shop")}
                </NavLink>
              </li>
              {/* <li className="tw-mx-4" ><NavLink href="/pros" onClick={toggle}>{t('navigation:Pro')}</NavLink></li> */}
              <li className="tw-mx-4">
                <NavLink href="#partenaire" onClick={toggle}>
                  {t("navigation:Partners")}
                </NavLink>
              </li>
              {lng?.slice(0, 2) === "fr" && (
                <li className="tw-mx-4">
                  <NavLink href="/jobs" onClick={toggle}>
                    {t("navigation:Jobs")}
                  </NavLink>
                </li>
              )}
              <li className="tw-mx-4">
                <NavLink href="/faq" onClick={toggle}>
                  Faq
                </NavLink>
              </li>
              <li className="tw-mx-4">
                <NavLink href="/contact" onClick={toggle}>
                  {t("navigation:contact")}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className={`navbar ${isOpen ? "showMenu" : ""}`}>
            {links && (
              <div className="d-flex social">
                <ul className="d-flex social-icon">
                  <li>
                    <a
                      target="_blank"
                      href={links?.instagram ? links.instagram : "#"}
                      rel="noreferrer"
                      onClick={toggle}
                    >
                      <img
                        src={require("../assets/img/instagram.png")}
                        className="icon-filter"
                        style={{ width: 18, height: 18 }}
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={links?.tiktok ? links.tiktok : "#"}
                      rel="noreferrer"
                      onClick={toggle}
                    >
                      <img
                        src={require("../assets/img/tik-tok.png")}
                        className="icon-filter"
                        style={{ width: 18, height: 18 }}
                        alt="tik-tok"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={links?.facebook ? links.facebook : "#"}
                      rel="noreferrer"
                      onClick={toggle}
                    >
                      <img
                        src={require("../assets/img/facebook.png")}
                        className="icon-filter"
                        style={{ width: 18, height: 18 }}
                        alt="facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={links?.linkedin ? links?.linkedin : "#"}
                      rel="noreferrer"
                      onClick={toggle}
                    >
                      <img
                        src={require("../assets/img/linkedin.png")}
                        className="icon-filter"
                        style={{ width: 18, height: 18 }}
                        alt="linkedin"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={links?.whatsapp ? links.whatsapp : "#"}
                      rel="noreferrer"
                      onClick={toggle}
                    >
                      <img
                        src={require("../assets/img/whatsapp.png")}
                        className="icon-filter"
                        style={{ width: 22, height: 22 }}
                        alt="whatsapp"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={links?.youtube ? links.youtube : "#"}
                      rel="noreferrer"
                      onClick={toggle}
                    >
                      <img
                        src={require("../assets/img/youtube.png")}
                        className="icon-filter"
                        style={{ width: 22, height: 22 }}
                        alt="whatsapp"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className="d-flex flex-row align-items-center">
            {/* { panier.length > 0 && */}

            <div style={{ marginRight: 18 }} className=" shopingCard">
              <IconButton onClick={handleClick} aria-label="cart">
                <StyledBadge badgeContent={panier.length} color="secondary">
                  <ShoppingCartIcon />
                </StyledBadge>
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div
                  className=" tw-flex tw-flex-col tw-pb-2 tw-h-[350px] tw-overflow-scroll "
                  style={{ width: onlyWidth <= 438 ? onlyWidth : 400 }}
                >
                  <div className=" tw-w-['calc(100%-18px)'] tw-min-h-[50px] tw-border-b-[1px] tw-flex tw-items-center tw-pl-3 tw-border-slate-300 tw-mx-[5px]  ">
                    <span className=" tw-font-semibold tw-font-['Poppins'] ">
                      {t("navigation:Mycarts")}
                    </span>
                  </div>

                  {panier.map((article, i) => (
                    <div
                      key={i}
                      className="tw-relative tw-rounded-md tw-h-[130px] tw-mt-3 tw-ring-[1px] tw-ring-slate-300 tw-flex tw-self-center tw-items-center"
                      style={{
                        width: onlyWidth <= 438 ? onlyWidth - 80 : "95%",
                        marginRight: onlyWidth <= 438 ? 40 : 0,
                      }}
                    >
                      <div className=" tw-h-[120px] tw-my-[5px] tw-flex tw-flex-row tw-flex-wrap tw-w-full">
                        <div className="tw-h-[100%] tw-w-[75px] tw-ml-2">
                          <img
                            src={
                              `${process.env.REACT_APP_PUBLIC_URL}/events/logo/` +
                              article.event.logo
                            }
                            fill
                            className="tw-rounded-[4px] tw-w-full tw-h-full  "
                            alt={"event"}
                          />
                        </div>

                        <div className=" tw-flex tw-flex-1 tw-flex-col tw-ml-2 tw-justify-center ">
                          <span className=" tw-font-bold tw-font-['Poppins'] tw-line-clamp-1 tw-mt-1">
                            {article.event.event}
                          </span>

                          <span className=" tw-ine-clamp-1 tw-font-normal tw-text-[13px] tw-mt-1">
                            {dayjs(article.event.start_date).format(
                              "DD-MM-YYYY",
                            )}{" "}
                            au{" "}
                            {dayjs(article.event.end_date).format("DD-MM-YYYY")}
                          </span>

                          <button
                            onClick={() => {
                              navigate(`./reservation/${article.event.id}`, {
                                state: article.event,
                              });
                            }}
                            className=" tw-bg-[#E7075B] tw-font-medium tw-text-white tw-text-[11px] tw-mt-2 tw-rounded-lg md:tw-w-[65%] tw-w-[85%] tw-h-[30px] hover:shadow-md focus:ring-2"
                          >
                            {t("general:continue")}
                          </button>
                        </div>
                      </div>

                      <button
                        onClick={() =>
                          handleDeleteArticle({ article: article, index: i })
                        }
                        className=" tw-w-[25px] tw-h-[25px] tw-bg-red-600 tw-absolute -tw-right-[8px] -tw-top-[8px] tw-rounded-full tw-flex tw-justify-center tw-items-center "
                      >
                        <IoCloseCircleOutline style={{ color: "white" }} />
                      </button>
                    </div>
                  ))}
                </div>
              </Menu>
            </div>
            {/* } */}

            <div style={{ marginRight: 18 }} className="language">
              <IconButton onClick={handleClick2} aria-label="cart">
                {lng?.slice(0, 2) === "fr" || lng?.slice(0, 2) === "FR" ? (
                  <img
                    alt="flag-fr"
                    style={{ width: 28, height: 28 }}
                    src={require("../../src/assets/img/france.png")}
                  />
                ) : (
                  <img
                    alt="flag-Kg"
                    style={{ width: 28, height: 28 }}
                    src={require("../../src/assets/img/kigdom.png")}
                  />
                )}
              </IconButton>

              <Menu
                anchorEl={anchorEl2}
                id="account-menu"
                open={open2}
                onClose={handleClose2}
                onClick={handleClose2}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div
                  className=" tw-flex tw-flex-col tw-pb-2 "
                  style={{ width: 200, height: 90 }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose2();
                      dispatch({ type: "SET_LANGUAGE", payload: "fr" });
                      i18n.changeLanguage("fr");
                    }}
                  >
                    <ListItemIcon>
                      <img
                        alt="flag-fr"
                        style={{ width: 20, height: 20 }}
                        src={require("../../src/assets/img/france.png")}
                      />
                    </ListItemIcon>
                    <ListItemText>Français</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose2();
                      dispatch({ type: "SET_LANGUAGE", payload: "en" });
                      i18n.changeLanguage("en");
                    }}
                  >
                    <ListItemIcon>
                      <img
                        alt="flag-fr"
                        style={{ width: 20, height: 20 }}
                        src={require("../../src/assets/img/kigdom.png")}
                      />
                    </ListItemIcon>
                    <ListItemText>Anglais</ListItemText>
                  </MenuItem>
                </div>
              </Menu>
            </div>

            <div onClick={toggle} className="humberger cursor-pointer ">
              {isOpen ? <MdClose /> : <FiMenu />}
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navigation;
