import "./tarifcard.css";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { batch, useDispatch, useSelector } from "react-redux";
import { setTarif } from "../../redux/reducers/reservations";
import { setTarifPass } from "../../redux/reducers/reservations";
import { setReservationPlace } from "../../redux/reducers/reservations";
import CircularProgress from "@mui/material/CircularProgress";
import { createReservation } from "../../redux/reducers/reservations";
import Tarifs from "./Tarifs";
import { CgRadioChecked } from "react-icons/cg";
import TarifPass from "./TarifPass";
import { timerDelay } from "../../utils";
import { useTranslation } from "react-i18next";
import { ReservationApi } from "../../api/reservationsApi";
import { getAnalytics, logEvent } from "firebase/analytics";
const dayjs = require("dayjs");

// const timerDelay = process.env.REACT_TIMER_DELAY

const TarifCard = ({ event }) => {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const lockerStock = useSelector((state) => state.locker);

  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );

  const reservationNewLoad = useSelector(
    (state) => state.reservationReducer.reservationNewLoad,
  );
  const lng = useSelector((state) => state.params.langage);

  const dispatch = useDispatch();
  const {
    availableStock,
    availableStockPass,
    initialStock,
    stockByContainer,
    lockerType,
    tarifsLocker,
  } = lockerStock;

  const eventPlaces = event.place;

  // set initial stock and available stock depending on place choosen

  const [stock, setStock] = useState(
    reservation.container_details?.availableStock || availableStock,
  );
  const [stockPass, setStockPass] = useState(
    reservation.container_details?.availableStockPass || availableStockPass,
  );
  const [initial, setInitial] = useState(
    reservation.container_details?.stock || initialStock,
  );
  const [place, setPlace] = useState(reservation.place || "");

  const handleChange = (e, l_type, type, date) => {
    // disable other checkbox
    const checkboxes = document.querySelectorAll(
      '.checkbox-group input[type="checkbox"]',
    );
    checkboxes.forEach((checkbox) => {
      if (checkbox !== e.target) {
        checkbox.checked = false;
      }
    });
    let container_serial_nb = "";
    let container_details = "";
    // if there is only one container, take the Container_serial_nb

    if (!reservation.container_serial_nb) {
      if (Object.keys(stockByContainer).length === 1) {
        container_serial_nb = stockByContainer[0].serial_nb;
        container_details = stockByContainer[0];
      } else {
        container_details = stockByContainer;
      }
    } else {
      container_serial_nb = reservation.container_serial_nb;
      container_details = reservation.container_details;
    }

    const info = {
      service_fee: parseInt(e.target.value),
      tarif_type: t(`tarifcard:${type}`),
      locker_type: l_type,
    };
    if (reservation.pass) {
      dispatch(
        setTarifPass(info, container_serial_nb, container_details, date),
      );
    } else {
      dispatch(setTarif(info, container_serial_nb, container_details, date));
    }
  };

  // set place
  const handlePlace = (e) => {
    // disable other checkbox
    const checkboxes = document.querySelectorAll(
      '.checkbox-group input[type="checkbox"]',
    );
    checkboxes.forEach((checkbox) => {
      if (checkbox !== e.target) {
        checkbox.checked = false;
      }
    });

    // delete locker_type, tarif_type, service_fee in reservation dates
    const dates = reservation.dates.map((date) => {
      return { date: date.date };
    });

    dispatch({ type: "RESET_RESERVATION", payload: { dates: dates } });

    const choosenPlace = e;

    setPlace((place) => (place === e ? "" : choosenPlace));

    let container_serial_nb = "";
    let container_details = "";

    const container_name = eventPlaces[choosenPlace].container_name;
    const constainer = stockByContainer.find((c) => c.name === container_name);

    container_serial_nb = constainer.serial_nb;
    container_details = constainer;

    //update stock by place

    setInitial((initial) => (initial = constainer.stock));
    setStock((stock) => (stock = constainer.availableStock));
    setStockPass((stockPass) => (stockPass = constainer.availableStockPass));

    dispatch(
      setReservationPlace(choosenPlace, container_serial_nb, container_details),
    );
  };

  const handleNext = async () => {
    // check if a tarif is selected for each dates
    const allTarifSelected = reservation.dates.filter(
      (date) => !date.locker_type,
    );
    if (allTarifSelected.length > 0) {
      return NotificationManager.error(
        t("tarifcard:errorchoose"),
        "OUPS !",
        3000,
      );
    }

    if (eventPlaces && Object.keys(eventPlaces).length > 1) {
      if (place.length == 0) {
        return NotificationManager.error(
          t("tarifcard:placechooserror"),
          "OUPS !",
          3000,
        );
      }
    }

    let maxdate = 0;
    let refdate = 0;
    if (reservation.reservation_date) {
      maxdate =
        dayjs(
          dayjs(reservation.reservation_date)
            .add(timerDelay, "m")
            .utc()
            .format(),
        ).unix() * 1000;
      refdate = dayjs(dayjs().add(0, "s").utc().format()).unix() * 1000;
    }

    if (!reservation.uid || refdate > maxdate) {
      dispatch({
        type: "SET_RESERVATIONNEW_LOAD",
        payload: { loading: true, error: false },
      });

      try {
        const res = await ReservationApi.createReservation(reservation);

        if (res) {
          const info = {
            ...reservation,
            uid: res.uid,
            container_id: reservation.dates[0].container_id,
            reservation_date: dayjs().utc(),
          };

          logEvent(analytics, "add_to_cart", {
            currency: "EUR",
            value: info.dates.reduce((acc, date) => acc + date.service_fee, 0),
            items: [
              {
                item_id: info.event_id,
                item_name: event.event,
                affiliation: event.organizer_name,
              },
            ],
          });

          reservation.dates.reduce((acc, date) => acc + date.service_fee, 0);

          batch(() => {
            dispatch({
              type: "SET_RESERVATIONNEW_LOAD",
              payload: { loading: false, error: false },
            });
            dispatch({
              type: "CREATE_RESERVATION",
              payload: {
                uid: res.uid,
                container_serial_nb: res.container_serial_nb,
                container_details: res.container_details,
                place: place,
                reservation_date: dayjs().utc(),
              },
            });
            dispatch({
              type: "SET_CLIENT_DETAILS_PANIER",
              payload: {
                id: reservation.event_id,
                info: info,
              },
            });
            dispatch({
              type: "SET_TIMER",
              payload:
                dayjs(dayjs().add(timerDelay, "m").utc().format()).unix() *
                1000,
            });
            if (
              reservation?.services?.includes("camping") ||
              reservation?.services?.includes("battery")
            ) {
              dispatch({ type: "NEXT_STEP" });
            } else {
              dispatch({ type: "SET_STEP", payload: 4 });
            }
          });
        } else {
          NotificationManager.error(
            t("general:errorconnexion"),
            "OUPS !",
            5000,
          );
          dispatch({
            type: "SET_RESERVATIONNEW_LOAD",
            payload: { loading: false, error: true },
          });
        }
      } catch (err) {
        NotificationManager.error(t("general:errorconnexion"), "OUPS !", 5000);
        dispatch({
          type: "SET_RESERVATIONNEW_LOAD",
          payload: { loading: false, error: true },
        });
      }
    } else {
      const info = {
        ...reservation,
        uid: reservation.uid,
        container_id: reservation.dates[0].container_id,
        reservation_date: reservation.reservation_date,
      };

      logEvent(analytics, "add_to_cart", {
        currency: "EUR",
        value: info.dates.reduce((acc, date) => acc + date.service_fee, 0),
        items: [
          {
            item_id: info.event_id,
            item_name: event.event,
            affiliation: event.organizer_name,
          },
        ],
      });

      batch(() => {
        dispatch({
          type: "SET_CLIENT_DETAILS_PANIER",
          payload: {
            id: reservation.event_id,
            info: info,
          },
        });

        dispatch({
          type: "SET_TIMER",
          payload:
            dayjs(
              dayjs(reservation.reservation_date)
                .add(timerDelay, "m")
                .utc()
                .format(),
            ).unix() * 1000,
        });
        if (
          reservation?.services?.includes("camping") ||
          reservation?.services?.includes("battery")
        ) {
          dispatch({ type: "NEXT_STEP" });
        } else {
          dispatch({ type: "SET_STEP", payload: 4 });
        }
      });
    }
  };

  const handleBack = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  };

  return (
    <div className="d-flex flex-column tarif-div ml-2">
      {eventPlaces && Object.keys(eventPlaces).length > 1 && (
        <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-rounded-md tw-gap-x-5 tw-gap-y-4 tw-pt-[30px] tw-px-4 tw-ring-slate-400 position-relative tw-flex-wrap tw-flex tw-justify-center tw-flex-row">
          {Object.keys(eventPlaces).map((key, index) => (
            <div
              key={index}
              onClick={() => handlePlace(key)}
              className={
                "tw-max-w-[160px] tw-min-w-[130px] tw-px-2 tw-h-[110px] tw-cursor-pointer tw-relative tw-font-medium tw-ring-[0.5px] tw-ring-[rgba(231,7,91,0.2)] tw-flex tw-justify-center tw-items-center tw-rounded-md " +
                (place === key
                  ? "tw-bg-[#119A36] tw-text-white"
                  : "tw-bg-[rgba(241,124,175,0.1)]  ")
              }
            >
              {/* {t(`tarifcard:${key}`)} */}
              {lng?.slice(0, 2) === "fr" || lng?.slice(0, 2) === "FR"
                ? key
                : eventPlaces[key].place_en}

              {place === key ? (
                <div className=" tw-w-[35px] tw-h-[35px] tw-absolute tw-flex tw-justify-center tw-items-center -tw-top-[14px] tw-rounded-full tw-bg-slate-600 ">
                  <CgRadioChecked size={20} />
                </div>
              ) : null}
            </div>
          ))}

          <div
            className="position-absolute px-3 tw-mx-4  "
            style={{
              top: -15,
              left: 0,
              backgroundColor: "#FFF8F8",
              height: 30,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span className="title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px]">
              {reservation.dates && !reservation.pass
                ? reservation.dates.length > 1
                  ? t("tarifcard:placechoose")
                  : t("tarifcard:placechoose")
                : t("tarifcard:placechoose")}
            </span>
          </div>
        </div>
      )}

      <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-rounded-md tw-ring-slate-400 position-relative tw-flex tw-items-center tw-flex-col">
        <section className="tw-mt-3">
          {lockerStock &&
            reservation.dates &&
            !reservation.pass &&
            reservation.dates.map((date, index) => (
              <div key={index}>
                <Tarifs
                  colapOpen={index === 0 ? true : false}
                  tarifs={tarifsLocker}
                  lockerType={lockerType}
                  availableStock={stock}
                  initialStock={initial}
                  date={date}
                  dateStart={event.start_date}
                  handleChange={handleChange}
                />
                {/* <TarifsNew/> */}
              </div>
            ))}
        </section>

        <section className="tw-mt-3">
          {lockerStock && reservation.dates && reservation.pass && (
            <div>
              <TarifPass
                tarifs={tarifsLocker}
                colapOpen={true}
                lockerType={lockerType}
                availableStockPass={stockPass}
                initialStock={initial}
                dates={reservation.dates}
                dateStart={event.start_date}
                handleChange={handleChange}
              />
            </div>
          )}
        </section>

        <div
          className=" position-absolute px-3 d-flex tw-mx-4 tw-self-start "
          style={{
            top: -15,
            backgroundColor: "#FFF8F8",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className=" title-forfait tw-text-[12px] sm:tw-text-[14px] md:tw-text-[15px] ">
            {reservation.dates && !reservation.pass
              ? reservation.dates.length > 1
                ? t("tarifcard:textchoose")
                : t("tarifcard:textchoose")
              : t("tarifcard:textchoose")}
          </span>
        </div>
      </div>

      <div className="tw-w-full tw-flex tw-flex-row tw-gap-x-4 tw-gap-y-2 tw-mt-4 tw-flex-wrap ">
        {tarifsLocker.hasOwnProperty("tarifPromo") && (
          <div className=" tw-flex tw-flex-row ">
            <span className=" tw-font-bold tw-text-[14px] tw-font-['Poppins'] ">
              EARLY*:
            </span>
            <span className="tw-text-[13px] tw-ml-2 tw-mt-[1px] tw-font-['Poppins'] ">
              {t("tarifcard:tpromo")}
            </span>
          </div>
        )}

        {tarifsLocker.hasOwnProperty("tarifNormal") && (
          <div className=" tw-flex tw-flex-row ">
            <span className=" tw-font-bold tw-text-[14px] tw-font-['Poppins'] ">
              REGULAR*:
            </span>
            <span className="tw-text-[13px] tw-ml-2 tw-mt-[1px] tw-font-['Poppins'] ">
              {t("tarifcard:tnormal")}
            </span>
          </div>
        )}

        {tarifsLocker.hasOwnProperty("tarifDM") &&
          Object.keys(tarifsLocker).length > 1 && (
            <div className=" tw-flex tw-flex-row">
              <span className=" tw-font-bold tw-text-[14px] tw-font-['Poppins'] ">
                LAST MINUTE*:
              </span>
              <span className="tw-text-[13px] tw-ml-2 tw-mt-[1px] tw-font-['Poppins'] ">
                {t("tarifcard:tdm")}
              </span>
            </div>
          )}
      </div>

      <div className="d-flex align-items-center action_buton">
        <button
          disabled={reservationNewLoad.loading}
          onClick={() => handleBack()}
          className="prev_btn"
        >
          {t("general:prev")}
        </button>

        <button
          disabled={reservationNewLoad.loading}
          onClick={() => handleNext()}
          className="next_btn"
        >
          {reservationNewLoad.loading ? (
            <CircularProgress size={30} sx={{ color: "#fff" }} />
          ) : (
            t("general:next")
          )}
        </button>
      </div>
    </div>
  );
};

export default TarifCard;
