const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
// const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

export const PayementApi = {
  async verifyPromoCode(event_id, code) {
    const response = await fetch(baseUrl + `/promo/verifyCodePromo`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event_id: event_id,
        code: code,
      }),
    });
    const data = await response.json();

    if (response.ok) return data;

    return null;
  },
};
