import "./successPage.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { updateReserationPanier } from "../../redux/reducers/reservations";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getEvents, getSuccessPaymentInfo } from "../../api/vivaPaymentApi";
import solo from "../../assets/icons/s+/kit solo.png";
import duo from "../../assets/icons/s+/Kit duo.png";
import quatro from "../../assets/icons/s+/kit quatro.png";
import galcie from "../../assets/icons/s+/Glaciere.png";
import oreil from "../../assets/icons/s+/oreiller.png";
import lamp from "../../assets/icons/s+/lampe.png";
import chaise from "../../assets/icons/s+/chaise.png";
import tabou from "../../assets/icons/s+/tabouret.png";
import batery from "../../assets/icons/image 130.png";
import batts from "../../assets/icons/s+/batterie.png";

const dayjs = require("dayjs");

const SuccessPage = () => {
  const [events, setEvents] = useState();
  const [reservationNew, setReservationNew] = useState();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderCode = searchParams.get("s");
  const load = useSelector((state) => state?.params?.loadSuccess);
  const panier = useSelector((state) => state?.panierReducer?.panier);
  const reservation = useSelector(
    (state) => state?.reservationReducer?.reservationInProgres,
  );
  // const reservationNew = useSelector((state) => state.reservationReducer.newReservation) || []

  const batt = [
    {
      id: "battery1",
      name: "BATTERIE STANDARD",
      price: 6,
      icon: batery,
    },
    {
      id: "battery2",
      name: "BATTERIE ILLIMITEE",
      price: 10,
      icon: batts,
    },
  ];

  const kit = [
    {
      id: "ks1",
      name: "KIT SOLO",
      price: 89,
      icon: solo,
    },
    {
      id: "ks2",
      name: "KIT DUO",
      price: 159,
      icon: duo,
    },
    {
      id: "ks3",
      name: "KIT QUATRO",
      price: 299,
      icon: quatro,
    },
  ];

  const extras = [
    {
      id: "et1",
      name: "GLACIERE 30 LITRES",
      price: 30,
      icon: galcie,
    },
    {
      id: "et2",
      name: "OREILLER 40/40 CM",
      price: 18,
      icon: oreil,
    },
    {
      id: "et3",
      name: "LAMPE TORCHE",
      price: 5,
      icon: lamp,
    },
    {
      id: "et4",
      name: "CHAISE PLIANTE",
      price: 20,
      icon: chaise,
    },
    {
      id: "et5",
      name: "TABOURET",
      price: 10,
      icon: tabou,
    },
  ];

  useEffect(() => {
    if (orderCode) {
      getSuccessPaymentInfo(orderCode).then((res) => {
        console.log(res.data);
        setReservationNew(res?.data[0]);
      });
    }
  }, []);

  useEffect(() => {
    if (reservationNew) {
      getEvents(reservationNew?.event_id).then((res) => {
        setEvents(res?.data);
      });
    }
  }, [reservationNew]);

  useEffect(() => {
    if (reservation?.uid) {
      const index = panier?.findIndex(
        (article) => article?.event?.id === reservation?.event_id,
      );

      let reservationSend = {
        ...reservation,
        dates: reservation?.dates.map((date) => JSON.stringify(date)),
      };

      let reservationSend2 = {
        ...reservation,
        ...panier?.[index]?.event,
        status: "payed",
        dates: reservation?.dates,
      };

      dispatch(updateReserationPanier(reservationSend, reservationSend2));
    }
  }, []);

  useEffect(() => {
    if (!load) {
      NotificationManager.success(
        t("pagesuccess:succedFlash"),
        t("pagesuccess:successPay"),
        5000,
      );
    }
  }, [load]);

  const navigate = useNavigate();

  /*  if(load || !state?.prov )
    {
        return (

            <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center" >
                <CircularProgress sx={{color: '#E7075B'}} />
            </div>
        )
    } */

  return (
    <div className="success-page container tw-flex tw-flex-col md:tw-flex-row md:tw-justify-evenly ">
      <div className="zigzag tw-flex tw-flex-col tw-self-center md:tw-self-auto tw-w-[90%] md:tw-w-[30%] tw-min-h-[550px] tw-py-[25px] tw-my-4 tw-bg-[#212529] ">
        <div className="">
          <div className=" tw-w-full tw-text-white tw-flex tw-text-center tw-text-[18px] tw-justify-center tw-font-semibold tw-mt-[10px] ">
            MY LOCKER {t("pagesuccess:ticket")}
          </div>
          <div className=" tw-w-full tw-text-white tw-flex tw-text-center tw-text-[14.5px] tw-justify-center tw-font-semibold tw-mt-[10px] ">
            {t("pagesuccess:reserv")}: #{reservationNew?.uid}
          </div>
          <div className=" tw-w-full tw-text-white tw-flex tw-text-center tw-text-[14.5px] tw-justify-center tw-font-semibold tw-mt-[10px] ">
            {reservationNew?.client_last_name} {reservationNew?.client_name}
          </div>

          <div className="tw-pt-[10px] tw-mx-3 tw-border-b-[1px] tw-border-white tw-border-dashed " />
        </div>

        <div className=" tw-h-[120px] tw-mt-[10px] tw-flex tw-flex-row tw-w-full">
          <div className="tw-h-[100%] tw-w-[80px] tw-ml-2">
            <img
              src={
                `${process.env.REACT_APP_PUBLIC_URL}/events/logo/` +
                events?.logo
              }
              className="tw-rounded-[4px] tw-w-full tw-h-full  "
              alt={"event"}
            />
          </div>

          <div className=" tw-flex tw-flex-1 tw-flex-col tw-ml-2 tw-justify-center ">
            <span className=" tw-font-semibold tw-font-['Poppins'] tw-text-white tw-line-clamp-2 tw-mt-1">
              {events?.event}
            </span>

            <span className=" tw-ine-clamp-1 tw-font-normal tw-text-white tw-text-[13px] tw-mt-2">
              {dayjs(events?.start_date).utc().format("DD-MM-YYYY")} au{" "}
              {dayjs(events?.end_date).utc().format("DD-MM-YYYY")}
            </span>

            <span className=" tw-ine-clamp-1 tw-font-normal tw-text-white tw-text-[13px] tw-mt-2">
              {events?.city}, {events?.country}
              {/* {dayjs(article.event.start_date).format('DD-MM-YYYY')} au {dayjs(article.event.end_date).format('DD-MM-YYYY')}        */}
            </span>
          </div>
        </div>

        <div className="tw-pt-[10px] tw-mx-3 tw-border-b-[1px] tw-border-white tw-border-dashed " />

        {reservationNew?.dates?.map((date, id) => (
          <div
            key={id}
            className="d-flex tw-self-center align-items-start justify-content-between md:tw-px-[2.5%] tw-px-[5px] tw-w-full tarif-container tw-mt-3 "
          >
            <div className=" tw-flex tw-flex-col ">
              <p className=" tw-font-['Poppins'] tw-font-medium tw-text-[12px] tw-text-[#F17CAF] ">
                {dayjs(date.date).format("dddd DD MMMM YYYY").toUpperCase()}
              </p>

              <div className="tw-flex tw-flex-col tw-items-start tw-justify-start">
                {date.battery &&
                  date.battery.map((batt) => (
                    <p className=" tw-font-['Poppins'] tw-text-[#fff]  tw-ml-2 ">
                      • 1 x {batt.name} = {batt.price + " €"}
                    </p>
                  ))}

                {date.kit_camping &&
                  date.kit_camping.map((kitt) => (
                    <p className=" tw-font-['Poppins'] tw-text-[#fff] tw-ml-2 ">
                      • 1 x {kitt.name} = {kitt.price + " €"}
                    </p>
                  ))}

                {date.extra &&
                  date.extra.map((extra) => (
                    <p className=" tw-font-['Poppins'] tw-text-[#fff] tw-ml-2 ">
                      • 1 x {extra.name} = {extra.price + " €"}
                    </p>
                  ))}

                {date.service_fee && (
                  <p className=" tw-font-['Poppins'] tw-text-[#fff] tw-ml-2 ">
                    • 1 x Locker ({date?.locker_type.toUpperCase()}) ={" "}
                    {date?.service_fee + " €"}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className="tw-pt-[10px] tw-mx-3 tw-border-b-[1px] tw-border-white tw-border-dashed " />

        {reservationNew?.discount && (
          <div className=" tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-3 tw-mx-5 ">
            <span className=" tw-text-white tw-font-semibold">
              {t("pagesuccess:reduction")}
            </span>
            <span className=" tw-text-white tw-font-medium tw-text-[13px] ">
              - {reservationNew?.discount} %
            </span>
          </div>
        )}

        <div className=" tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-3 tw-mx-5 ">
          <span className=" tw-text-white tw-font-semibold">
            {t("pagesuccess:total")}
          </span>
          <span className=" tw-text-white tw-font-medium tw-text-[13px] ">
            {reservationNew?.total} €
          </span>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-self-center md:tw-self-auto tw-pb-4 tw-w-[90%] md:tw-w-[60%] tw-bg-white tw-px-3 tw-my-4 tw-rounded-md">
        <div className="tw-w-full tw-flex tw-mt-5 tw-items-center tw-justify-center ">
          {/* <FiCheckCircle size={55} color="green" /> */}
          <span className="tw-font-semibold tw-font-['Poppins'] tw-text-[20px] tw-ml-4 ">
            {t("pagesuccess:titresucc")}
          </span>
        </div>

        <div className="tw-font-['Poppins'] tw-mt-5 tw-text-[16px] tw-mx-4">
          {t("pagesuccess:thanks")}{" "}
          <span className=" tw-font-semibold">
            {reservationNew?.client_last_name} {reservationNew?.client_name}{" "}
          </span>
        </div>
        <div className="tw-font-['Poppins'] tw-mt-2 tw-text-[16px] tw-mx-4">
          {t("pagesuccess:reservtext1")}{" "}
          <span className=" tw-font-semibold">#{reservationNew?.uid}</span>{" "}
          {t("pagesuccess:reservtext2")}{" "}
          <span className=" tw-font-semibold tw-text-[#E7075B]">SMS</span>{" "}
          {t("pagesuccess:reservtext3")}{" "}
          <span className=" tw-font-semibold tw-text-[#E7075B]">MAIL</span>{" "}
          {t("pagesuccess:reservtext4")}
        </div>

        <div className="tw-font-['Poppins'] tw-mt-2 tw-text-[13px] tw-text-center tw-mx-4">
          ({t("pagesuccess:itallicMention")})
        </div>

        <div className="tw-font-['Poppins'] tw-mt-2 tw-text-[16px] tw-mx-4">
          {t("pagesuccess:etape")}
        </div>

        <div className=" tw-w-full tw-flex-col lg:tw-flex-row tw-flex tw-items-center tw-gap-y-[10px] lg:tw-justify-between tw-mt-[20px]">
          <div className=" tw-w-[92%] lg:tw-w-[35%] tw-min-h-[250px] tw-rounded-lg tw-overflow-hidden tw-bg-[#F17CAF] ">
            <div className=" tw-w-full tw-h-[70px] tw-bg-[#E7075B] tw-flex-row tw-flex tw-items-center ">
              <div className=" tw-w-[45px] tw-h-[45px] lg:tw-w-[55px] lg:tw-h-[55px] tw-ml-2 tw-justify-center tw-items-center tw-flex tw-text-white tw-font-bold tw-text-[18px] tw-bg-black tw-rounded-full ">
                1
              </div>

              <div className=" tw-flex tw-flex-1 tw-flex-col tw-pl-2 ">
                <span className=" tw-text-white tw-text-[15px] tw-font-semibold ">
                  {t("pagesuccess:avant")}
                </span>
                <span className=" tw-text-white tw-text-[15px] tw-font-semibold ">
                  {t("pagesuccess:event")}
                </span>
              </div>
            </div>

            <div className=" tw-mt-3 tw-items-center tw-flex tw-h-[80px] tw-px-2 tw-text-center tw-text-slate-900 tw-text-[12px] tw-font-medium ">
              {t("pagesuccess:etap1")}
            </div>

            <div className=" tw-w-full  tw-flex tw-justify-center tw-my-2">
              <img
                src={require("../../assets/img/etape1.png")}
                className="tw-object-contain"
                style={{ width: "95%", height: 120 }}
                alt="whatsapp"
              />
            </div>
          </div>

          <div className=" tw-w-[92%] lg:tw-w-[32%] tw-min-h-[250px] tw-rounded-lg tw-overflow-hidden tw-bg-[#F17CAF]">
            <div className=" tw-w-full tw-h-[70px] tw-bg-[#E7075B] tw-flex-row tw-flex tw-items-center">
              <div className=" tw-w-[45px] tw-h-[45px] lg:tw-w-[55px] lg:tw-h-[55px] tw-ml-2 tw-justify-center tw-items-center tw-flex tw-text-white tw-font-bold tw-text-[18px] tw-bg-black tw-rounded-full ">
                2
              </div>

              <div className=" tw-flex tw-flex-1 tw-flex-col tw-pl-2 ">
                <span className=" tw-text-white tw-text-[15px] tw-font-semibold ">
                  {t("pagesuccess:pendant")}
                </span>
                <span className=" tw-text-white tw-text-[15px] tw-font-semibold ">
                  {t("pagesuccess:event")}
                </span>
              </div>
            </div>

            <div className=" tw-mt-3 tw-h-[80px] tw-items-center tw-flex tw-px-2 tw-text-center tw-text-slate-900 tw-text-[12px] tw-font-medium ">
              {t("pagesuccess:etap2")}
            </div>

            <div className=" tw-w-full tw-flex tw-justify-center tw-my-2 ">
              <img
                src={require("../../assets/img/etape2.png")}
                className="tw-object-contain"
                style={{ width: "95%", height: 120 }}
                alt="whatsapp"
              />
            </div>
          </div>

          <div className=" tw-w-[92%] lg:tw-w-[30%] tw-min-h-[250px] tw-rounded-lg tw-overflow-hidden tw-bg-[#F17CAF] ">
            <div className=" tw-w-full tw-h-[70px] tw-bg-[#E7075B] tw-flex-row tw-flex tw-items-center ">
              <div className=" tw-w-[45px] tw-h-[45px] lg:tw-w-[55px] lg:tw-h-[55px] tw-ml-2 tw-justify-center tw-items-center tw-flex tw-text-white tw-font-bold tw-text-[18px] tw-bg-black tw-rounded-full ">
                3
              </div>

              <div className=" tw-flex tw-flex-1 tw-flex-col tw-pl-2 ">
                <span className=" tw-text-white tw-text-[15px] tw-font-semibold ">
                  {t("pagesuccess:apres")}
                </span>
                <span className=" tw-text-white tw-text-[15px] tw-font-semibold ">
                  {t("pagesuccess:event")}
                </span>
              </div>
            </div>

            <div className="tw-h-[80px] tw-mt-3 tw-px-2 tw-items-center tw-flex tw-text-center tw-text-slate-900 tw-text-[12px] tw-font-medium ">
              {t("pagesuccess:etap3")}
            </div>

            <div className=" tw-w-full tw-flex tw-justify-center tw-my-2">
              <img
                src={require("../../assets/img/etape3.png")}
                className="tw-object-contain"
                style={{ width: "95%", height: 120 }}
                alt="whatsapp"
              />
            </div>
          </div>
        </div>

        <div className=" tw-w-full tw-flex tw-justify-center tw-mt-8 ">
          <button
            onClick={() => navigate("/")}
            className=" tw-w-[40%] tw-h-[40px] tw-rounded tw-bg-green-500 tw-text-white tw-font-medium tw-text-[13px] "
          >
            {t("pagesuccess:return")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
