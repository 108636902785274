import { all } from "redux-saga/effects";
import { timersappSaga } from "./timerSaga";
// import {statsappSaga} from "./statsappSaga"
// import {albumsappSaga} from "./albumsappSaga"
// import { usersappSaga } from './usersappSaga';

export function* rootSaga() {
  yield all([
    ...timersappSaga,
    // ...albumsappSaga,
    // ...usersappSaga
  ]);
}
