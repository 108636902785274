import { useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const config = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    "Content-Type": "application/json",
  },
};

const AnnulerDialog = ({ id }) => {
  const dialog = useSelector((state) => state.params.dialog);
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [load, setload] = useState(false);

  const handleClose = () => {
    dispatch({ type: "SET_DIALOG", payload: { active: false, view: null } });
  };

  const handleSubmit = async () => {
    setload(true);

    try {
      const url = `${process.env.REACT_APP_PUBLIC_URL}/reservations/annulation/${id}`;
      const res = await axios.post(url, {}, config);

      setload(false);
      dispatch({ type: "SET_DIALOG", payload: { active: false, view: null } });
      NotificationManager.success(t("pagecancel:cancelFlash"), "", 5000);
      navigate("/");

      logEvent(analytics, "refund", {
        currency: "EUR",
        transaction_id: dialog.uid,
        value: dialog.total,
        coupon: dialog.code_promo,
        tax: 0.8,
        items: [
          {
            item_id: dialog.id,
            item_name: dialog.event,
          },
        ],
      });
    } catch (error) {
      setload(false);
    }
  };

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "#E7075B",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span className="tw-font-bold tw-text-[15px] tw-text-white">
          {t("dialog:titleConfirmCancel")}
        </span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="tw-text-sm tw-mt-3">
            {t("dialog:textConfirmCancel")} <br />
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          disabled={load}
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]"
          onClick={handleSubmit}
        >
          {load ? (
            <CircularProgress size={16} sx={{ color: "white" }} />
          ) : (
            t("dialog:yes")
          )}
        </button>
        <button
          disabled={load}
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700"
          onClick={() => handleClose()}
        >
          {t("dialog:no")}
        </button>
      </DialogActions>
    </>
  );
};

export default AnnulerDialog;
