import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import configureStore from "../redux/ConfigStore";

import en from "./en";
import fr from "./fr";

const Store = configureStore();

const LANGUAGES = {
  en,
  fr,
};

const langDetector = new LanguageDetector();
langDetector.addDetector({
  name: "userSettingDetector",
  lookup(options) {
    return;
  },
  cacheUserlanguage(lng, caches) {
    console.log(caches);
    // Store.dispatch({type: "SET_LANGUAGE", payload: lng})
  },
});

i18n
  // detect language
  .use(langDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // set options
  .init({
    resources: LANGUAGES,
    fallbackLng: "fr-FR",
    debug: false,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    detection: {
      // order and from where user language should be detected
      order: [
        "userSettingDetector",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: "myDomain",

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
  });
