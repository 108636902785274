import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeltonPickDate = () => {
  return (
    <div className="d-flex tw-w-[95%] tw-self-center flex-column">
      {Array.from({ length: 3 }).map((k, i) => (
        <Skeleton
          key={i}
          variant="rectangular"
          animation="wave"
          sx={{ bgcolor: "grey.300", borderRadius: 2, marginY: 1 }}
          width={"100%"}
          height={80}
        />
      ))}
    </div>
  );
};

export default SkeltonPickDate;
