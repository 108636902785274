import axios from "axios";
import produce from "immer";
// import eventData from '../../dummyData';

const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

const GET_EVENTS = "GET_EVENTS";
const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";
const FILTER_EVENT = "FILTER_EVENT";

export const getEvents = () => async (dispatch) => {
  dispatch({ type: "LOAD_EVENTS", payload: true });
  const response = await axios.get(url);
  const eventData = response.data;
  const cleanEventData = [];
  for (let i = 0; i < eventData.length; i++) {
    const attributeToRemove = [
      "event_manager_id",
      "organiser_email",
      "organizer_name",
      "organizer_id",
      "admin_id",
    ];

    const cleanEvent = { ...eventData[i] };

    for (let j = 0; j < attributeToRemove.length; j++) {
      delete cleanEvent[attributeToRemove[j]];
    }
    cleanEventData.push(cleanEvent);
  }

  dispatch({
    type: GET_EVENTS,
    payload: cleanEventData,
  });
  dispatch({ type: "LOAD_EVENTS", payload: false });
};

export const getEventById = (id) => async (dispatch) => {
  const response = await axios.get(`${url}/${id}`);
  const event = response.data;
  dispatch({
    type: GET_EVENT_BY_ID,
    payload: event[0],
  });
};

// filtered by items in filtered group
export const filterEvents = (events, filteredGroup) => async (dispatch) => {
  // get event where event.type include
  let eventsFiltered = [];
  if (filteredGroup.length > 0) {
    eventsFiltered = events.filter((event) => {
      if (filteredGroup.includes(event.type)) return event;
    });
  } else {
    eventsFiltered = events;
  }

  dispatch({
    type: FILTER_EVENT,
    payload: { eventsFiltered, filteredGroup },
  });
};

const eventappState = {
  allEvents: [],
  filteredEvents: [],
  filtreGroupe: [],
};

const eventReducer = (state = eventappState, action) => {
  let nextState;

  switch (action.type) {
    case GET_EVENTS:
      nextState = produce(state, (draftState) => {
        draftState.allEvents = action.payload;
        draftState.filteredEvents = action.payload;
      });

      return nextState || state;

    case GET_EVENT_BY_ID:
      return action.payload;

    case FILTER_EVENT:
      nextState = produce(state, (draftState) => {
        draftState.filtreGroupe = action.payload.filteredGroup;
        draftState.filteredEvents = action.payload.eventsFiltered;
      });

      return nextState || state;

    default:
      return state;
  }
};

export default eventReducer;
