import React, { useEffect } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import { getPartners } from "../../redux/reducers/partners";
import { useDispatch, useSelector } from "react-redux";

const Partenaire = () => {
  const load = useSelector((state) => state.params.loadPartners);
  const partners = useSelector((state) => state.partnersReducer.allPartners);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
  };

  return (
    <div
      style={{ backgroundColor: "#F2F2F2" }}
      className="w-100  container-fluid py-4"
      id="partenaire"
    >
      <div style={{ backgroundColor: "#F2F2F2" }} className="container-fluid">
        {/* <hr className="divider" style={{height: 1}} /> */}

        <div
          className="d-flex flex-column justify-content-center pb-3"
          style={{ marginTop: 30 }}
        >
          <span id="title-part" className="tw-mb-8 tw-leading-[35px]">
            {t("partenaire:Title")}
          </span>
          {/* <span id='subtitle-part' className="mx-2" >{t("partenaire:Subtitle")}</span> */}

          <div style={{ marginTop: 30 }} className="tw-w-[90%] tw-self-center">
            {load ? (
              <CircularProgress sx={{ color: "#E7075B" }} />
            ) : (
              <Slider {...settings}>
                {partners.length > 0 &&
                  partners.map((k, i) => (
                    <div key={i} className="tw-w-[60px] tw-h-[60px] my-2">
                      <a
                        target="_blank"
                        href={k?.link ? k.link : "#"}
                        rel="noreferrer"
                      >
                        <img
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          alt={k.name}
                          src={
                            `${process.env.REACT_APP_PUBLIC_URL}/cms/file/` +
                            k.logo
                          }
                        />
                      </a>
                    </div>
                  ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partenaire;
