import { memo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteReservation } from "../../redux/reducers/reservations";
import { NotificationManager } from "react-notifications";
import Countdown, { CountdownApi } from "react-countdown";

const Timer = ({ date }) => {
  const dispatch = useDispatch();
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );
  const timerStatus = useSelector((state) => state.timerReducer);

  const handleOnCompleteTimer = () => {
    dispatch({ type: "TIMERAUTOCOMPLETE", payload: reservation });
  };

  const countRef = useRef(null);
  // console.log(countRef.current);

  useEffect(() => {
    if (timerStatus.timerStatus === "pause") {
      countRef.current.pause();
      dispatch({ type: "PUT_TIMER_STATUS", payload: null });
    }

    if (timerStatus.timerStatus === "play") {
      countRef.current.start();
      dispatch({ type: "PUT_TIMER_STATUS", payload: null });
    }

    if (timerStatus.timerStatus === "stop") {
      countRef.current.stop();
      dispatch({ type: "PUT_TIMER_STATUS", payload: null });
    }
  }, [timerStatus.timerStatus]);

  return (
    <>
      <Countdown
        date={date}
        ref={countRef}
        renderer={({ minutes, seconds }) => (
          <span className=" tw-text-white ">
            {minutes < 10 ? minutes : minutes}:
            {seconds < 10 ? "0" + seconds : seconds}
          </span>
        )}
        onComplete={() => handleOnCompleteTimer()}
        onStop={() => handleOnCompleteTimer()}
      />
    </>
  );
};

export default memo(Timer);
