import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import { FiClock } from "react-icons/fi";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const JobDetails = () => {
  const location = useLocation();
  const job = location.state.data;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="w-100 ">
      <div
        style={{ height: 300 }}
        className="container-fluid w-100 job-details-header d-flex flex-column justify-content-center align-items-center"
      >
        <span style={{ width: "70%" }} className="job-details-title">
          {job.title}
        </span>

        <div
          style={{ marginTop: 15 }}
          className="d-flex flex-1 flex-row align-items-center "
        >
          <div className="d-flex flex-row align-items-center">
            <IoLocationSharp
              style={{ fontSize: 22, color: "white", marginRight: 4 }}
            />
            <span className="text-job" style={{ color: "white" }}>
              {job.place}
            </span>
          </div>

          <div
            className="d-flex flex-row align-items-center"
            style={{ marginLeft: 30 }}
          >
            <FiClock style={{ fontSize: 20, color: "white", marginRight: 4 }} />
            <span className="text-job" style={{ color: "white" }}>
              {job.time}
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div style={{ width: "100%" }} className="mt-3 ">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {/* {breadcrumbs} */}
            <span className="bread">
              <NavLink
                to="../jobs"
                style={{
                  textDecoration: "none",
                }}
                id="bread"
              >
                {t("jobs:vacant")}
              </NavLink>
            </span>
            <span>{job.title}</span>
          </Breadcrumbs>
        </div>

        <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center md:tw-items-start tw-items-center flex-md-row">
          <div className="d-flex flex-column w-100 flex-1">
            {/* <p className="px-md-5 text-justify" >
                            {job.description}
                        </p> */}

            <div
              className="mentionDiv px-md-5 text-justify"
              dangerouslySetInnerHTML={{
                __html: job.description,
              }}
            />
          </div>

          <div className=" tw-flex tw-flex-col tw-mt-8 ">
            <button
              onClick={() => {
                dispatch({
                  type: "SET_DIALOG",
                  payload: {
                    active: true,
                    view: "postuler",
                    value: {
                      id: job.id,
                    },
                  },
                });
              }}
              type="button"
              className="job-button-postuler"
            >
              {t("jobs:postuler")}
            </button>

            <a
              href={"https://" + job.indeed_link}
              target="_blank"
              className=" tw-w-[220px] tw-h-[48px] tw-rounded-[6px] tw-justify-center tw-items-center tw-flex tw-text-[14px] tw-font-[600] tw-text-white hover:tw-text-white tw-text-['poppins'] tw-bg-[#003A9B] tw-mt-3 "
            >
              {t("jobs:indeed")}
              {/* Voir l'offre sur Indeed  */}
            </a>

            <a
              href={"https://" + job.linkedin_link}
              target="_blank"
              className=" tw-w-[220px] tw-h-[48px] tw-rounded-[6px] tw-justify-center tw-items-center tw-flex tw-text-[14px] tw-font-[600] tw-text-white hover:tw-text-white tw-text-['poppins'] tw-bg-[#0072b1]  tw tw-mt-3 "
            >
              {t("jobs:likedn")}
              {/* Voir l'offre sur Linkedin */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
