import axios from "axios";
import produce from "immer";

const url = `${process.env.REACT_APP_PUBLIC_URL}/cms/legalterms`;

const GET_TERMS = "GET_TERMS";
const GET_CGV = "GET_CGV";
const GET_CGU = "GET_CGU";
const GET_RGDP = "GET_RGDP";

export const getTerms = () => async (dispatch) => {
  dispatch({ type: "LOAD_TERMS", payload: true });
  const response = await axios.get(`${url}/terms`);
  const termsData = response.data;

  dispatch({
    type: GET_TERMS,
    payload: termsData,
  });
  dispatch({ type: "LOAD_TERMS", payload: false });
};

export const getCgv = () => async (dispatch) => {
  dispatch({ type: "LOAD_TERMS", payload: true });
  const response = await axios.get(`${url}/cgv`);
  const cgvData = response.data;

  dispatch({
    type: GET_CGV,
    payload: cgvData,
  });
  dispatch({ type: "LOAD_TERMS", payload: false });
};

export const getCgu = (lng) => async (dispatch) => {
  dispatch({ type: "LOAD_TERMS", payload: true });
  const response = await axios.get(`${url}/cgu`);
  const cguData = response.data;

  dispatch({
    type: GET_CGU,
    payload: cguData,
  });

  dispatch({ type: "LOAD_TERMS", payload: false });
};

export const getRgdp = () => async (dispatch) => {
  dispatch({ type: "LOAD_TERMS", payload: true });
  const response = await axios.get(`${url}/rgdp`);
  const rgdpData = response.data;

  dispatch({
    type: GET_RGDP,
    payload: rgdpData,
  });
  dispatch({ type: "LOAD_TERMS", payload: false });
};

const legalsState = {
  terms: "",
  cgv: "",
  cgu: "",
  rgdp: "",
};

const termsReducer = (state = legalsState, action) => {
  let nextState;

  switch (action.type) {
    case GET_TERMS:
      nextState = produce(state, (draftState) => {
        draftState.terms = action.payload;
      });

      return nextState || state;

    case GET_CGV:
      nextState = produce(state, (draftState) => {
        draftState.cgv = action.payload;
      });

      return nextState || state;

    case GET_CGU:
      nextState = produce(state, (draftState) => {
        draftState.cgu = action.payload;
      });

      return nextState || state;

    case GET_RGDP:
      nextState = produce(state, (draftState) => {
        draftState.rgdp = action.payload;
      });

      return nextState || state;

    default:
      return state;
  }
};

export default termsReducer;
