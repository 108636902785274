import React, { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CircularProgress from "@mui/material/CircularProgress";

import { getCgv } from "../../redux/reducers/legalText";
import { useDispatch, useSelector } from "react-redux";
import "./mentionLegal.css";

const Cgv = () => {
  const load = useSelector((state) => state.params.loadTerms);
  const cgv = useSelector((state) => state.termsReducer.cgv);
  const lng = useSelector((state) => state.params.langage);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCgv());
  }, [dispatch]);

  return (
    <div className="w-100 ">
      <div
        style={{ height: 300 }}
        className="container-fluid w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center"
      >
        <span style={{ width: "70%" }} className="job-details-title">
          {t("cgv:titrcgv").toUpperCase()}
        </span>
      </div>

      <div className="container">
        <div style={{ width: "100%" }} className="mt-3 ">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {/* {breadcrumbs} */}
            <span className="bread">
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                }}
                id="bread"
              >
                {t("rgpd:home")}
              </NavLink>
            </span>
            <span>{t("cgv:titrcgv")}</span>
          </Breadcrumbs>
        </div>

        {load ? (
          <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
            <CircularProgress sx={{ color: "#E7075B" }} />
          </div>
        ) : (
          <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
            <div style={{ width: "90%" }} className="w-100">
              {lng?.slice(0, 2) === "fr" || lng?.slice(0, 2) === "FR" ? (
                <div
                  className="mentionDiv"
                  dangerouslySetInnerHTML={{
                    __html: cgv.cgv,
                  }}
                />
              ) : (
                <div
                  className='"mentionDiv"'
                  dangerouslySetInnerHTML={{
                    __html: cgv.english_cgv,
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cgv;
