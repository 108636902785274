import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { NotificationManager } from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CircularProgress from "@mui/material/CircularProgress";
import { sendOptVerify } from "../../redux/reducers/optVerify";
import { setClientDetails } from "../../redux/reducers/reservations";
import { OtpApi } from "../../api/otpApi";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import "./identification.css";
import { Form, FormGroup, Label } from "reactstrap";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Identification = () => {
  const schema = yup
    .object({
      telephone: yup
        .string()
        .required("Le télephone est requis")
        .test("tel", "Télephone Invalide", (value) => {
          if (value && value?.length > 3) {
            let phonenumber = parsePhoneNumber("+" + value).number;
            if (isValidPhoneNumber(phonenumber)) {
              return true;
            }
            return false;
          } else {
            return false;
          }
        }),
    })
    .required();

  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation();
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );
  // const optCode = useSelector((state) => state.ontimepin.opt.code);

  const [otpVerify, setOtpVerify] = useState(false);
  const [reCap, setReCap] = useState(false);
  const [recapval, setRecapval] = useState(null);

  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({
    val: false,
    message: "",
  });
  const [otpwaiting, setOtpwaiting] = useState(false);

  const [optCode, setOptcode] = useState({
    code: null,
    load: false,
    error: false,
  });

  const recaptchaRef = useRef(null);

  const dispatch = useDispatch();

  const onRecapTch = async (recaptcha) => {
    const result = await axios.post(
      `${process.env.REACT_APP_ROOT_API}/verify-recaptch`,
      { recaptcha },
    );

    if (result.data.success) {
      setRecapval(recaptcha);
      return setReCap(true);
    }
  };

  const onExpired = () => {
    setRecapval(null);
  };

  const optSubmit = async (data) => {
    // console.log(data);

    if (reCap === false) {
      return NotificationManager.error(
        t("identification:confirmerobot2"),
        "Erreur",
        5000,
      );
    }

    // if (!info.client_phone || info.client_phone.length < 8 ) {
    //   return NotificationManager.error( t("identification:confirmernum"), 'Erreur', 5000);
    // }

    setInfo((prev) => ({ ...prev, client_phone: data.telephone }));
    setOptcode({ ...optCode, load: true, code: null });

    const res = await OtpApi.createOtp({
      telephone: data.telephone,
    });

    if (res) {
      setOptcode({ ...optCode, load: false, code: res.success });
      setOtpwaiting(true);
    } else {
      setOptcode({ ...optCode, load: false, code: false });
    }
  };

  const verifyCode = async (e) => {
    if (e.target.value.length > 5) {
      if (e.target.value.length > 6) {
        setErrors({
          val: true,
          message: t("identification:errortotalchiffre"),
        });
      } else {
        const code = e.target.value;
        const res = await OtpApi.verifyOtp({
          telephone: info.client_phone,
          code: code,
        });

        if (res.success) {
          setErrors({
            val: false,
            message: t("identification:codetrue"),
          });
          setOtpVerify(true);
          setOtpwaiting(false);
        } else {
          setErrors({
            val: true,
            message: t("identification:codefalse"),
          });
        }
      }
    } else {
      setOtpVerify(false);
      setErrors({
        val: true,
        message: "",
      });
    }
  };

  const handleNext = () => {
    if (!recapval) {
      return NotificationManager.error(
        t("identification:confirmerobot2"),
        "Erreur",
        5000,
      );
    }

    if (!info.client_phone || otpVerify === false) {
      return NotificationManager.error(
        t("identification:confirmernum"),
        "Erreur",
        5000,
      );
    }

    batch(() => {
      dispatch(setClientDetails({ ...info, authentification: true }));
      dispatch({
        type: "SET_CLIENT_DETAILS_PANIER",
        payload: {
          id: reservation.event_id,
          info: { ...info, authentification: true },
        },
      });
      dispatch({ type: "NEXT_STEP", payload: "formulaire" });
    });
  };

  const handleBack = () => {
    if (
      reservation?.services?.includes("camping") ||
      reservation?.services?.includes("battery")
    ) {
      dispatch({ type: "PREVIOUS_STEP" });
    } else {
      dispatch({ type: "SET_STEP", payload: 2 });
    }
  };

  const Otpwaitingtext = () => {
    // timer for 30 sec?
    const [time, setTime] = useState(15);
    setTimeout(() => {
      setTime(time - 1);
    }, 1000);

    useEffect(() => {
      if (time === 0) {
        setOtpwaiting(false);
      }
    }, [time]);

    return (
      <div className="tw-mb-3.5">
        <small>
          {t("identification:resentext1")}{" "}
          <strong>
            {time} {t("identification:resentext2")}{" "}
          </strong>
          {t("identification:resentext3")}
        </small>
      </div>
    );
  };

  return (
    <div className="ident-div tw-w-full md:tw-mt-0 tw-mt-8 d-flex flex-column align-items-center">
      <div className="tw-w-full tw-flex tw-flex-col ">
        <div
          className={
            "position-relative tw-ring-1  tw-px-3 tw-pt-[30px] tw-pb-4 tw-rounded-md tw-w-full tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-justify-center tw-items-center " +
            (recapval ? "tw-ring-green-200" : "tw-ring-slate-300 ")
          }
        >
          <div
            className=" tw-text-[14px] sm:tw-mr-2 tw-mr-0  "
            style={{ fontWeight: "bold" }}
          >
            {t("identification:confirmerobot")}
          </div>

          <div className="tw-mx-2 md:tw-mt-0 tw-mt-4 ">
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
              onChange={onRecapTch}
              ref={recaptchaRef}
              onExpired={onExpired}
              size="compact"
              // onErrored={onRecapError}
            />
          </div>

          <div
            className={
              "tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-font-bold tw-w-[50px] tw-h-[50px] tw-absolute -tw-top-[18px] -tw-left-[18px] tw-rounded-full " +
              (recapval ? "tw-bg-green-500" : "tw-bg-slate-300 ")
            }
          >
            1
          </div>
        </div>

        {reCap && (
          <div
            className={
              "position-relative tw-ring-1 tw-mt-10 tw-px-3 tw-pt-[40px] tw-pb-4 tw-rounded-md tw-w-full tw-flex tw-flex-col " +
              (optCode.code ? "tw-ring-green-200" : "tw-ring-slate-300 ")
            }
          >
            <span id="exLabel" className=" tw-mb-4">
              {t("identification:ajoutertel")}
            </span>

            <div className=" tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 ">
              <div
                className=" tw-text-[14px] tw-flex tw-flex-1 "
                style={{ fontWeight: "bold" }}
              >
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { invalid, error },
                  }) => (
                    <div className="tw-flex tw-flex-col tw-w-full ">
                      <PhoneInput
                        country={"fr"}
                        {...field}
                        countryCodeEditable={false}
                        containerClass="tw-min-w-[160px] tw-max-w-[340px]"
                        inputClass=" !tw-h-[40px] !tw-w-full "
                      />

                      {error && (
                        <span className=" tw-text-[10px] tw-font-normal tw-text-red-400 tw-mt-2">
                          {error.message}
                        </span>
                      )}
                    </div>
                  )}
                  name="telephone"
                  control={control}
                />
              </div>

              <div className="">
                {!otpwaiting ? (
                  <button
                    className="tw-h-[40px] tw-flex tw-justify-center tw-items-center"
                    onClick={handleSubmit(optSubmit)}
                  >
                    {optCode.load ? (
                      <CircularProgress size={25} sx={{ color: "#fff" }} />
                    ) : optCode.code ? (
                      t("identification:resendcode")
                    ) : (
                      t("identification:sendcode")
                    )}
                  </button>
                ) : (
                  <Otpwaitingtext />
                )}
              </div>
            </div>

            <div
              className={
                "tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-font-bold tw-w-[50px] tw-h-[50px] tw-absolute -tw-top-[18px] -tw-left-[18px] tw-rounded-full " +
                (optCode.code ? "tw-bg-green-500" : "tw-bg-slate-300 ")
              }
            >
              2
            </div>
          </div>
        )}

        {optCode.code && (
          <div
            className={
              "position-relative tw-ring-1 tw-mt-10 tw-px-3 tw-pt-[40px] tw-pb-4 tw-rounded-md tw-w-full tw-flex tw-flex-col " +
              (otpVerify ? "tw-ring-green-200" : "tw-ring-slate-300 ")
            }
          >
            <span id="exLabel" className=" tw-mb-4">
              {t("identification:tapecoderecu")}
            </span>

            <div className=" tw-flex tw-flex-row tw-flex-wrap tw-gap-x-3 ">
              <div
                className="tw-text-[14px] tw-flex tw-flex-1"
                style={{ fontWeight: "bold" }}
              >
                <FormGroup className="tw-flex tw-w-full">
                  {/* <Label for="optcode">{t("identification:tapecoderecu")}</Label> */}

                  <input
                    id="optcode"
                    placeholder={t("identification:plhtapecoderecu")}
                    type="text"
                    onChange={(e) => verifyCode(e)}
                    className="form-control tw-h-[40px] tw-min-w-[160px] tw-max-w-[350px] tw-self-start"
                  />
                  <small className="error text-danger">{errors["opt"]}</small>
                </FormGroup>
              </div>

              <div className="">
                <div
                  className={
                    " tw-text-center tw-py-2 tw-px-5 tw-rounded-md tw-text-white tw-flex tw-justify-center tw-items-center " +
                    (errors.val
                      ? errors.message.length === 0
                        ? "tw-bg-transparent"
                        : "tw-bg-red-500"
                      : errors.message.length === 0
                        ? " tw-bg-transparent"
                        : "tw-bg-green-500")
                  }
                >
                  {errors.message}
                </div>
              </div>
            </div>

            <div
              className={
                "tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-font-bold tw-w-[50px] tw-h-[50px] tw-absolute -tw-top-[18px] -tw-left-[18px] tw-rounded-full " +
                (otpVerify ? "tw-bg-green-500" : "tw-bg-slate-300 ")
              }
            >
              3
            </div>
          </div>
        )}
      </div>

      <div className="d-flex align-items-center action_buton">
        <button onClick={() => handleBack()} className="prev_btn">
          {t("general:prev")}
        </button>

        <button onClick={() => handleNext()} className="next_btn">
          {t("general:next")}
        </button>
      </div>
    </div>
  );
};

export default Identification;
