import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const HomeThreeSingleFaq = ({ question, answer }) => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion
      flush
      className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"
      open={open}
      toggle={toggle}
    >
      <AccordionItem className="tw-my-2 tw-w-[95%] tw-rounded-lg tw-ring-[1px] tw-ring-slate-200 tw-border-0 bg-danger job-details-header tw-text-white">
        <AccordionHeader
          className=" tw-ring-[1px] tw-ring-slate-200"
          targetId="1"
        >
          <strong>{question}</strong>
        </AccordionHeader>
        <AccordionBody
          accordionId="1"
          className=" tw-ring-[1px] tw-ring-slate-200 tw-text-white"
        >
          {answer}
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default HomeThreeSingleFaq;
