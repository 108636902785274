import { useDispatch, useSelector, batch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ReservationDialog = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );

  const handleClose = () => {
    let data = {};
    data.event_id = reservation.event_id;
    data.dates = [];
    // reservation.dates.forEach(function(item){
    //     data.dates.push({
    //         container_id: item.container_id,
    //         date: item.date
    //     });
    // });
    data.container_id = reservation.container_id;
    if (reservation?.pass) {
      data.pass = reservation.pass;
    }

    batch(() => {
      dispatch({ type: "SET_DIALOG", payload: { active: false, view: null } });
      dispatch({ type: "SET_TIMER", payload: false });
      dispatch({ type: "RESET_RESERVATION_PROGRES", payload: data });
    });

    navigate(`../`);
  };

  const onSubmit = async () => {
    dispatch({ type: "SET_DIALOG", payload: { active: false, view: null } });
  };

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "#E7075B",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span className="tw-font-bold tw-text-[15px] tw-text-white">
          {t("dialog:titleCancel")}
        </span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="tw-text-sm tw-mt-3">{t("dialog:textCancel")}</div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]"
          onClick={() => onSubmit()}
        >
          {t("dialog:backToResa")}
        </button>
        <button
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700"
          onClick={() => handleClose()}
        >
          {t("dialog:backToHome")}
        </button>
      </DialogActions>
    </>
  );
};

export default ReservationDialog;
