import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./tarif.css";
import { Form, FormGroup, Label, Input } from "reactstrap";
const dayjs = require("dayjs");
// import { useSelector } from 'react-redux';

const lockerSize = {
  xs: "25cm x 40cm x 20cm",
  s: "25cm x 40cm x 30cm",
  m: "25cm x 40cm x 40cm",
  l: "25cm x 40cm x 50cm",
  xl: "25cm x 40cm x 60cm",
  mb: "80cm x 100cm x 20cm",
};

const TarifPass = (props) => {
  const { t } = useTranslation();
  const {
    tarifs,
    lockerType,
    availableStockPass,
    initialStock,
    dates,
    handleChange,
    colapOpen,
    dateStart,
  } = props;
  const today = dayjs(dayjs().utc().format("YYYY-MM-DD")).unix() * 1000;
  const datesub60 =
    dayjs(dayjs(dateStart).subtract(60, "day").utc().format()).unix() * 1000;
  const datesub30 =
    dayjs(dayjs(dateStart).subtract(30, "day").utc().format()).unix() * 1000;

  const early_is_stockOut = (type) => {
    // retrun true if availablestock for type is less than 85% initialstock
    if (availableStockPass.hasOwnProperty(type)) {
      return availableStockPass[type] < initialStock[type] * 0.85;
    }

    return false;
  };

  const regular_is_stockOut = (type) => {
    // retrun true if availablestock for type is less than 50% initialstock
    if (availableStockPass.hasOwnProperty(type)) {
      return availableStockPass[type] < initialStock[type] * 0.5;
    }

    return false;
  };

  const getAvailableStock = (type) => {
    if (availableStockPass) {
      // if availableStockPass has key type? return availableStockPass[type] else return initialStock[type]
      const result = availableStockPass.hasOwnProperty(type)
        ? availableStockPass[type]
        : initialStock[type] || 0;
      return parseInt(result);
    }
    return "...";
  };

  const handler = (e, l_type, id, date) => {
    handleChange(e, l_type, id, date);
  };

  const [collapse, setCollapse] = useState(colapOpen || false);
  const toggle = () => setCollapse(!collapse);

  return (
    <section className="tw-flex tw-w-100 tw-flex-col tw-justify-center tw-items-center">
      <section className="d-flex align-items-center justify-content-between md:tw-w-[90%] tw-w-[95%] tw-flex-wrap tarif-container tw-my-4 ">
        <div>
          <span className=" tw-font-['Poppins'] tw-font-semibold tw-text-[10px] sm:tw-text-[11px] md:tw-text-[13px] ">
            {t("tarifcard:durer")}{" "}
          </span>

          {dates[0]?.tarif_type && (
            <button
              onClick={toggle}
              className=" tw-mt-4 tw-mb-2 tw-bg-[#E7075B] button-modif"
            >
              {t("tarifcard:modif")}
            </button>
          )}
        </div>

        {dates[0]?.locker_type && (
          <div
            className={
              " tw-relative tw-w-[220px] tw-rounded-[4px] tw-h-[110px] tw-flex tw-flex-row " +
              (dates[0].locker_type === "mb"
                ? "tw-bg-[#66B7FF]"
                : "tw-bg-[#F17CAF]")
            }
          >
            <div
              className={
                " tw-w-[calc(35%+10px)] tw-rounded-l-[4px] tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col " +
                (dates[0].locker_type === "mb"
                  ? "tw-bg-[rgba(55,71,166,1)]"
                  : "tw-bg-[#E7075B]")
              }
            >
              <span className=" tw-mb-2 tw-text-white tw-font-light tw-text-[12px] ">
                {t("tarifcard:size")}
              </span>

              <div className=" tw-w-[40px] tw-h-[40px] tw-ring-[2px] tw-font-bold tw-ring-white tw-text-white tw-flex tw-justify-center tw-items-center tw-rounded-full ">
                {dates[0].locker_type.toUpperCase()}
              </div>
            </div>

            <div className=" tw-w-[calc(100%-(35%+10px))] tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col ">
              <span className=" tw-font-['Poppins'] tw-text-center tw-text-[14px] ">
                {dates[0]?.tarif_type}
              </span>

              <span className="tw-mt-[5px] tw-font-['Poppins'] tw-font-bold tw-text-[20px] ">
                {dates[0]?.service_fee + " €"}
              </span>
            </div>

            <div className=" tw-w-[20px] tw-h-[20px] tw-rounded-full tw-left-[35%] -tw-top-[10px] tw-absolute tw-bg-[#FFF8F8]" />
            <div className=" tw-w-[20px] tw-h-[20px] tw-rounded-full tw-left-[35%] -tw-bottom-[10px] tw-absolute tw-bg-[#FFF8F8]" />
          </div>
        )}

        {!dates[0]?.tarif_type && (
          <button type="button" className="button-choos" onClick={toggle}>
            {" "}
            {t("tarifcard:choise")}{" "}
          </button>
        )}
      </section>

      <hr className="md:tw-w-[90%] tw-w-[95%]" />

      <Collapse isOpen={collapse}>
        <div className="d-flex tarif-section tw-mx-2 tw-justify-center ">
          <div className=" tw-hidden md:tw-block flex-column tw-mt-3 ">
            <div className=" tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-[170px] ">
              {tarifs.hasOwnProperty("tarifPromo") && (
                <div className=" tw-flex tw-justify-center tw-items-center tw-my-[10px] tw-h-[48px]">
                  <p className=" tw-mt-3 tw-font-bold">EARLY*</p>
                </div>
              )}

              {tarifs.hasOwnProperty("tarifNormal") && (
                <div className=" tw-flex tw-justify-center tw-items-center tw-my-[15px] tw-h-[48px]">
                  <p className=" tw-mt-3 tw-font-bold">REGULAR*</p>
                </div>
              )}

              {tarifs.hasOwnProperty("tarifDM") &&
                Object.keys(tarifs).length > 1 && (
                  <div className=" tw-flex tw-justify-center tw-items-center tw-my-[20px] tw-h-[48px]">
                    <p className=" tw-mt-3 tw-font-bold">LAST MINUTE*</p>
                  </div>
                )}
            </div>
          </div>

          {lockerType.map((type, index) => (
            <div key={index} className="d-flex flex-column">
              {tarifs[Object.keys(tarifs)[0]].hasOwnProperty(type) && (
                <Form className="d-flex flex-column align-items-center mt-2">
                  <p
                    className={
                      " tw-w-full tw-font-semibold tw-h-[30px] tw-text-[13px] tw-flex tw-justify-center tw-items-center " +
                      (getAvailableStock(type) === 0
                        ? "muted-badge"
                        : type === "mb"
                          ? "nouveau-badge"
                          : "locker_stock")
                    }
                  >
                    {getAvailableStock(type) > 0 ? getAvailableStock(type) : ""}{" "}
                    {getAvailableStock(type) > 0
                      ? t("tarifcard:dispo")
                      : t("tarifcard:indispo")}
                  </p>
                  <div className="locker-details">
                    <div
                      className={
                        type === "mb" ? "stock-blue-box" : "stock-pink-box"
                      }
                    >
                      <div className="locker-type-box">
                        <p className="locker-type-p tw-w-[50px] tw-flex tw-justify-center tw-items-center tw-rounded-full tw-font-bold tw-h-[50px] ">
                          {type.toUpperCase()}
                        </p>
                      </div>
                      <p className="text-white tf">{lockerSize[type]}</p>
                    </div>
                    <p className="locker-description mt-2 tw-w-full tw-font-semibold tw-h-[30px] tw-text-[13px] tw-flex tw-justify-center tw-items-center tw-px-1 ">
                      LOCKER
                    </p>
                  </div>

                  {tarifs.hasOwnProperty("tarifPromo") && (
                    <div className="checkbox-group tw-relative">
                      <FormGroup check inline>
                        <span className=" tw-absolute tw-font-semibold md:tw-hidden tw-block tw-top-1 tw-left-1 tw-text-[9px] ">
                          EARLY
                        </span>

                        <Label
                          check
                          className={
                            getAvailableStock(type) === 0 ||
                            early_is_stockOut(type)
                              ? "label-muted"
                              : ""
                          }
                        >
                          {getAvailableStock(type) === 0 ||
                          early_is_stockOut(type)
                            ? t("tarifcard:indispo")
                            : tarifs.tarifPromo[type] + " €"}

                          <Input
                            type="checkbox"
                            className="tarif-checkbox"
                            onChange={(e) => handler(e, type, "tpromo", dates)}
                            onClick={toggle}
                            disabled={
                              getAvailableStock(type) === 0 ||
                              early_is_stockOut(type)
                                ? true
                                : false
                            }
                            value={tarifs.tarifPromo[type]}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  )}

                  {tarifs.hasOwnProperty("tarifNormal") && (
                    <div className="checkbox-group tw-relative">
                      <FormGroup check inline>
                        <span className=" tw-absolute tw-font-semibold md:tw-hidden tw-block tw-top-1 tw-left-1 tw-text-[9px] ">
                          REGULAR
                        </span>

                        <Label
                          check
                          className={
                            getAvailableStock(type) === 0 ||
                            regular_is_stockOut(type)
                              ? "label-muted"
                              : ""
                          }
                        >
                          {getAvailableStock(type) === 0 ||
                          regular_is_stockOut(type)
                            ? t("tarifcard:indispo")
                            : tarifs.tarifNormal[type] + " €"}

                          <Input
                            type="checkbox"
                            className="tarif-checkbox"
                            onChange={(e) => handler(e, type, "tnormal", dates)}
                            onClick={toggle}
                            disabled={
                              getAvailableStock(type) === 0 ||
                              regular_is_stockOut(type)
                                ? true
                                : false
                            }
                            value={tarifs.tarifNormal[type]}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  )}

                  {tarifs.hasOwnProperty("tarifDM") && (
                    <div className="checkbox-group tw-relative ">
                      <FormGroup check inline>
                        <span className=" tw-absolute tw-font-semibold md:tw-hidden tw-block tw-top-1 tw-left-1 tw-text-[9px] ">
                          LAST MINUTE
                        </span>

                        <Label
                          check
                          className={
                            getAvailableStock(type) === 0 ? "label-muted" : ""
                          }
                        >
                          {getAvailableStock(type) === 0
                            ? t("tarifcard:indispo")
                            : tarifs.tarifDM[type] + " €"}

                          <Input
                            type="checkbox"
                            className="tarif-checkbox"
                            onChange={(e) => handler(e, type, "tdm", dates)}
                            onClick={toggle}
                            disabled={
                              getAvailableStock(type) === 0 ? true : false
                            }
                            value={tarifs.tarifDM[type]}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  )}
                </Form>
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </section>
  );
};

export default TarifPass;
