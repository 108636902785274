import { useDispatch, useSelector, batch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "firebase/analytics";

const DeletePanierDialog = ({ datas }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const reservation = useSelector(
    (state) => state.reservationReducer.reservationInProgres,
  );
  const panier = useSelector((state) => state.panierReducer.panier);

  const handleClose = () => {
    dispatch({ type: "SET_DIALOG", payload: { active: false, view: null } });
  };

  const onSubmit = async () => {
    if (reservation?.event_id === datas.article.event.id) {
      let data = {};
      data.event_id = reservation.event_id;
      data.dates = [];
      // reservation.dates.forEach(function(item){
      //     data.dates.push({
      //         container_id: item.container_id,
      //         date: item.date
      //     });
      // });
      data.container_id = reservation.container_id;
      if (reservation?.pass) {
        data.pass = reservation.pass;
      }

      batch(() => {
        dispatch({
          type: "SET_DIALOG",
          payload: { active: false, view: null },
        });
        dispatch({
          type: "DEL_RESERVATION_TO_PANIER",
          payload: datas.article.event.id,
        });
        dispatch({ type: "SET_TIMER", payload: false });
        // dispatch({type: 'SET_CLIENT_DETAILS', payload: data})
        dispatch({ type: "RESET_RESERVATION_PROGRES", payload: data });
        // dispatch({type: 'SET_STEP', payload: 0 })
      });

      navigate("/#events");

      logEvent(analytics, "remove_from_cart", {
        currency: "EUR",
        value: datas.article.reservation.dates.reduce(
          (acc, date) => acc + (date?.service_fee || 0),
          0,
        ),
        items: [
          {
            item_id: datas.article.event.id,
            item_name: datas.article.event.event,
            affiliation: datas.article.event.organizer_name,
          },
        ],
      });
    } else {
      batch(() => {
        dispatch({
          type: "SET_DIALOG",
          payload: { active: false, view: null },
        });
        dispatch({
          type: "DEL_RESERVATION_TO_PANIER",
          payload: datas.article.event.id,
        });
      });

      logEvent(analytics, "remove_from_cart", {
        currency: "EUR",
        value: datas.article.reservation.dates.reduce(
          (acc, date) => acc + (date?.service_fee || 0),
          0,
        ),
        items: [
          {
            item_id: datas.article.event.id,
            item_name: datas.article.event.event,
            affiliation: datas.article.event.organizer_name,
          },
        ],
      });
    }
  };

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "#E7075B",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span className="tw-font-bold tw-text-[15px] tw-text-white">
          {t("dialog:titleDelete")}
        </span>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="tw-text-sm tw-mt-3">
            {t("dialog:textDelete")}{" "}
            <span className=" tw-font-semibold ">
              {panier[datas.index].event.event}
            </span>{" "}
            {t("dialog:textDelete2")}
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-white tw-bg-[#E7075B]"
          onClick={() => onSubmit()}
        >
          {t("dialog:yes")}
        </button>
        <button
          className="tw-text-sm tw-font-semibold tw-h-[35px] tw-px-3 tw-rounded-md tw-text-slate-700"
          onClick={() => handleClose()}
        >
          {t("dialog:no")}
        </button>
      </DialogActions>
    </>
  );
};

export default DeletePanierDialog;
