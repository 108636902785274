import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FcCancel } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { getSlides } from "../../redux/reducers/slider";

const dayjs = require("dayjs");

const config = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    "Content-Type": "application/json",
  },
};

const CancelPage = () => {
  // get reservation id from url
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // check on api information about reservation
  const [loading, setLoading] = useState(true);
  const [cancelable, setCancelable] = useState(false);
  const [raison, setRaison] = useState("");
  const [reservationNew, setReservation] = useState(null);

  const links = useSelector((state) => state.sliderReducer.allLinks);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const url = `${process.env.REACT_APP_PUBLIC_URL}/reservations/annulation/${id}`;
        const res = await axios.get(url, config);
        setCancelable(res.data.cancelable);
        setReservation(res.data);
        setRaison(res.data.message);
        setLoading(false);
      } catch (error) {
        window.location.href = `/404`;
      }
    }

    fetchData();
    dispatch(getSlides());
  }, [id]);

  const handleCancel = () => {
    dispatch({
      type: "SET_DIALOG",
      payload: {
        active: true,
        view: "cancelRe",
        value: reservationNew.reservationData,
      },
    });
  };

  if (loading) {
    return (
      <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
        <CircularProgress sx={{ color: "#E7075B" }} />
      </div>
    );
  }

  return (
    <div className="success-page container tw-flex tw-flex-col md:tw-flex-row md:tw-justify-evenly">
      <div className="zigzag tw-flex tw-flex-col tw-self-center md:tw-self-auto tw-w-[90%] md:tw-w-[30%] tw-min-h-[550px] tw-py-[25px] tw-my-4 tw-bg-[#212529] ">
        <div className="">
          <div className=" tw-w-full tw-text-white tw-flex tw-text-center tw-text-[18px] tw-justify-center tw-font-semibold tw-mt-[10px] ">
            MY LOCKER {t("pagesuccess:ticket")}
          </div>
          {/* <div className=" tw-w-full tw-text-white tw-flex tw-text-center tw-text-[14.5px] tw-justify-center tw-font-semibold tw-mt-[10px] " >
                        {t("pagesuccess:reserv")}: #{reservationNew?.uid}
                    </div> */}
          <div className=" tw-w-full tw-text-white tw-flex tw-text-center tw-text-[14.5px] tw-justify-center tw-font-semibold tw-mt-[10px] ">
            {reservationNew?.reservationData.client_last_name}{" "}
            {reservationNew?.reservationData.client_name}
          </div>

          <div className="tw-pt-[10px] tw-mx-3 tw-border-b-[1px] tw-border-white tw-border-dashed " />
        </div>

        <div className=" tw-h-[120px] tw-mt-[10px] tw-flex tw-flex-row tw-w-full">
          <div className="tw-h-[100%] tw-w-[80px] tw-ml-2">
            <img
              src={
                `${process.env.REACT_APP_PUBLIC_URL}/events/logo/` +
                reservationNew?.eventData.logo
              }
              fill
              className="tw-rounded-[4px] tw-w-full tw-h-full  "
              alt={"event"}
            />
          </div>

          <div className=" tw-flex tw-flex-1 tw-flex-col tw-ml-2 tw-justify-center ">
            <span className=" tw-font-semibold tw-font-['Poppins'] tw-text-white tw-line-clamp-2 tw-mt-1">
              {reservationNew?.eventData.event}
            </span>

            <span className=" tw-ine-clamp-1 tw-font-normal tw-text-white tw-text-[13px] tw-mt-2">
              {dayjs(reservationNew?.eventData.start_date)
                .utc()
                .format("DD-MM-YYYY")}{" "}
              au{" "}
              {dayjs(reservationNew?.eventData.end_date)
                .utc()
                .format("DD-MM-YYYY")}
            </span>

            <span className=" tw-ine-clamp-1 tw-font-normal tw-text-white tw-text-[13px] tw-mt-2">
              {reservationNew?.eventData.city},{" "}
              {reservationNew?.eventData.country.split(",")[1]}
              {/* {dayjs(article.event.start_date).format('DD-MM-YYYY')} au {dayjs(article.event.end_date).format('DD-MM-YYYY')}        */}
            </span>
          </div>
        </div>

        <div className="tw-pt-[10px] tw-mx-3 tw-border-b-[1px] tw-border-white tw-border-dashed " />

        {reservationNew?.reservationData.pass ? (
          <div className="d-flex tw-self-center align-items-start justify-content-between md:tw-px-[2.5%] tw-px-[5px] tw-w-full tarif-container tw-mt-3 ">
            <div className=" tw-flex tw-flex-col tw-max-w-[55%]">
              <span className=" tw-font-['Poppins'] tw-font-medium tw-text-[11px] tw-text-white ">
                {t("pagesuccess:du")}{" "}
                {dayjs(reservationNew?.eventData.start_date)
                  .utc()
                  .format("dddd DD MMMM YYYY")
                  .toUpperCase()}{" "}
                {t("pagesuccess:au")}{" "}
                {dayjs(reservationNew?.eventData.end_date)
                  .utc()
                  .format("dddd DD MMMM YYYY")
                  .toUpperCase()}
              </span>

              <div className=" tw-font-['Poppins'] tw-mt-2 tw-font-semibold tw-text-[12px] tw-text-white ">
                {/* Zone choisi= */}
                {/* {dayjs(date.date).format('dddd DD MMMM YYYY').toUpperCase()} */}
              </div>
            </div>

            <div
              className={
                " tw-relative tw-w-[185px] tw-rounded-[4px] tw-h-[80px] tw-flex tw-flex-row " +
                (JSON.parse(reservationNew?.reservationData.dates[0])
                  .locker_type === "mb"
                  ? "tw-bg-[#66B7FF]"
                  : "tw-bg-[#F17CAF]")
              }
            >
              <div
                className={
                  " tw-w-[calc(32%+10px)] tw-rounded-l-[4px] tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col " +
                  (JSON.parse(reservationNew?.reservationData.dates[0])
                    .locker_type === "mb"
                    ? "tw-bg-[rgba(55,71,166,1)]"
                    : "tw-bg-[#E7075B]")
                }
              >
                <span className=" tw-mb-2 tw-text-white tw-font-light tw-text-[12px] ">
                  {t("tarifcard:size")}
                </span>

                <div className=" tw-w-[30px] tw-h-[30px] tw-ring-[2px] tw-font-bold tw-ring-white tw-text-white tw-text-[12px] tw-flex tw-justify-center tw-items-center tw-rounded-full ">
                  {JSON.parse(
                    reservationNew?.reservationData.dates[0],
                  ).locker_type?.toUpperCase()}
                </div>
              </div>

              <div className=" tw-w-[calc(100%-(32%+10px))] tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col ">
                <span className=" tw-font-['Poppins'] tw-text-center tw-text-[12.5px] ">
                  {
                    JSON.parse(reservationNew?.reservationData.dates[0])
                      .tarif_type
                  }
                </span>

                <span className="tw-mt-[2px] tw-font-['Poppins'] tw-font-bold tw-text-[20px] ">
                  {JSON.parse(reservationNew?.reservationData.dates[0])
                    .service_fee + " €"}
                </span>
              </div>

              <div className=" tw-w-[15px] tw-h-[15px] tw-rounded-full tw-left-[32%] -tw-top-[7px] tw-absolute tw-bg-[#212529]" />
              <div className=" tw-w-[15px] tw-h-[15px] tw-rounded-full tw-left-[32%] -tw-bottom-[7px] tw-absolute tw-bg-[#212529]" />
            </div>
          </div>
        ) : (
          reservationNew?.reservationData.dates.map((dat, id) => {
            const date = JSON.parse(dat);

            return (
              <div
                key={id}
                className="d-flex tw-self-center align-items-start justify-content-between md:tw-px-[2.5%] tw-px-[5px] tw-w-full tarif-container tw-mt-3 "
              >
                <div className=" tw-flex tw-flex-col ">
                  <span className=" tw-font-['Poppins'] tw-font-medium tw-text-[11px] tw-text-white ">
                    {dayjs(date.date).format("dddd DD MMMM YYYY").toUpperCase()}
                  </span>

                  <div className=" tw-font-['Poppins'] tw-mt-2 tw-font-semibold tw-text-[12px] tw-text-white ">
                    {/* Zone choisi= */}
                    {/* {dayjs(date.date).format('dddd DD MMMM YYYY').toUpperCase()} */}
                  </div>
                </div>

                <div
                  className={
                    " tw-relative tw-w-[185px] tw-rounded-[4px] tw-h-[80px] tw-flex tw-flex-row " +
                    (date.locker_type === "mb"
                      ? "tw-bg-[#66B7FF]"
                      : "tw-bg-[#F17CAF]")
                  }
                >
                  <div
                    className={
                      " tw-w-[calc(32%+10px)] tw-rounded-l-[4px] tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col " +
                      (date.locker_type === "mb"
                        ? "tw-bg-[rgba(55,71,166,1)]"
                        : "tw-bg-[#E7075B]")
                    }
                  >
                    <span className=" tw-mb-2 tw-text-white tw-font-light tw-text-[12px] ">
                      {t("tarifcard:size")}
                    </span>

                    <div className=" tw-w-[30px] tw-h-[30px] tw-ring-[2px] tw-font-bold tw-ring-white tw-text-white tw-text-[12px] tw-flex tw-justify-center tw-items-center tw-rounded-full ">
                      {date.locker_type?.toUpperCase()}
                    </div>
                  </div>

                  <div className=" tw-w-[calc(100%-(32%+10px))] tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col ">
                    <span className=" tw-font-['Poppins'] tw-text-center tw-text-[12.5px] ">
                      {date.tarif_type}
                    </span>

                    <span className="tw-mt-[2px] tw-font-['Poppins'] tw-font-bold tw-text-[20px] ">
                      {date.service_fee + " €"}
                    </span>
                  </div>

                  <div className=" tw-w-[15px] tw-h-[15px] tw-rounded-full tw-left-[32%] -tw-top-[7px] tw-absolute tw-bg-[#212529]" />
                  <div className=" tw-w-[15px] tw-h-[15px] tw-rounded-full tw-left-[32%] -tw-bottom-[7px] tw-absolute tw-bg-[#212529]" />
                </div>
              </div>
            );
          })
        )}

        <div className="tw-pt-[10px] tw-mx-3 tw-border-b-[1px] tw-border-white tw-border-dashed " />

        <div className=" tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-3 tw-mx-5 ">
          <span className=" tw-text-white tw-font-semibold">Total:</span>
          <span className=" tw-text-white tw-font-medium tw-text-[13px] ">
            {reservationNew?.reservationData.total} €
          </span>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-justify-between tw-self-center md:tw-self-auto tw-pb-4 tw-w-[90%] md:tw-w-[60%] tw-bg-white tw-px-3 tw-my-4 tw-rounded-md">
        <div className="tw-flex tw-flex-col">
          <div className="tw-w-full tw-flex tw-mt-5 tw-items-center tw-justify-center ">
            {/* <FcCancel size={55} color="green" /> */}
            <span className="tw-font-semibold tw-font-['Poppins'] tw-text-[20px] tw-ml-4 ">
              {t("pagecancel:titrecancel")}
            </span>
          </div>

          {cancelable && (
            <div>
              <div className="tw-font-['Poppins'] tw-mt-5 tw-text-[16px] tw-mx-4">
                <span className="tw-font-semibold ">
                  {t("pagecancel:quiz")}
                </span>
              </div>

              <div className="tw-font-['Poppins'] tw-mt-5 tw-text-[16px] tw-mx-4">
                <span className="tw-font-semibold ">
                  {t("pagecancel:quiz11")}
                </span>
              </div>
            </div>
          )}

          {!cancelable && (
            <div className="tw-font-['Poppins'] tw-mt-5 tw-text-[16px] tw-mx-4">
              <span className=" tw-font-semibold tw-text-[16px]">
                {t("pagecancel:quiz2")}
              </span>
            </div>
          )}

          {cancelable ? null : (
            <div className="tw-font-['Poppins'] tw-mt-2 tw-text-[16px] tw-mx-4">
              <br />
              {raison === "raison2" ? (
                <span className=" tw-font-semibold tw-text-[16px]">
                  {t(`pagecancel:${raison}`)}
                </span>
              ) : (
                <div>
                  <span>{t("pagecancel:subquiz2")}</span>
                  <span className=" tw-font-semibold tw-text-[16px]">
                    {dayjs(reservationNew?.eventData.start_date)
                      .utc()
                      .format("dddd DD MMMM YYYY")}
                    .
                  </span>
                </div>
              )}
            </div>
          )}

          <div className=" tw-w-full tw-flex tw-justify-evenly tw-mt-8 ">
            {cancelable ? (
              <button
                id="confir"
                onClick={() => handleCancel()}
                className=" tw-w-[40%] tw-h-[40px] tw-rounded tw-text-white tw-font-medium tw-text-[13px] "
              >
                {t("pagecancel:cancel")}
              </button>
            ) : null}

            <button
              onClick={() => navigate("/")}
              className=" tw-w-[40%] tw-h-[40px] tw-rounded tw-bg-green-600 tw-text-white tw-font-medium tw-text-[13px] "
            >
              {t("pagesuccess:return")}
            </button>
          </div>
        </div>

        <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-px-3 tw-rounded-md tw-ring-slate-600 position-relative tw-flex tw-items-right tw-flex-row tw-justify-evenly tw-flex-wrap">
          <a
            target="_blank"
            href={links.instagram}
            rel="noreferrer"
            className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black tw-text-slate-600 lg:tw-my-3 tw-my-1 tw-mx-2 "
          >
            <span className=" tw-mr-2 ">
              <img
                src={require("../../assets/img/instagram.png")}
                className="icon-filter"
                style={{ width: 20, height: 20 }}
                alt="instagram"
              />
            </span>
            Instagram
          </a>

          <a
            target="_blank"
            href={links.tiktok}
            rel="noreferrer"
            className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black tw-text-slate-600 lg:tw-my-3 tw-my-1 tw-mx-2 "
          >
            <span className=" tw-mr-2 ">
              <img
                src={require("../../assets/img/tik-tok.png")}
                className="icon-filter"
                style={{ width: 20, height: 20 }}
                alt="tik-tok"
              />
            </span>
            tik tok
          </a>

          <a
            target="_blank"
            href={links.facebook}
            rel="noreferrer"
            className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black tw-text-slate-600 lg:tw-my-3 tw-my-1 tw-mx-2 "
          >
            <span className=" tw-mr-2 ">
              <img
                src={require("../../assets/img/facebook.png")}
                className="icon-filter"
                style={{ width: 20, height: 20 }}
                alt="facebook"
              />
            </span>
            Facebook
          </a>

          <a
            target="_blank"
            href={links.whatsapp}
            rel="noreferrer"
            className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black tw-text-slate-600 lg:tw-my-3 tw-my-1 tw-mx-2 "
          >
            <span className=" tw-mr-2 ">
              <img
                src={require("../../assets/img/whatsapp.png")}
                className="icon-filter"
                style={{ width: 16, height: 16 }}
                alt="whatsapp"
              />
            </span>
            WhatsApp
          </a>

          <a
            target="_blank"
            href={links.youtube}
            rel="noreferrer"
            className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black tw-text-slate-600 lg:tw-my-3 tw-my-1 tw-mx-2 "
          >
            <span className=" tw-mr-2 ">
              <img
                src={require("../../assets/img/youtube.png")}
                className="icon-filter"
                style={{ width: 16, height: 16 }}
                alt="whatsapp"
              />
            </span>
            YouTube
          </a>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;
