import React, { useEffect, Fragment } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./mentionLegal.css";

const OnlineHelp = () => {
  const links = useSelector((state) => state.sliderReducer.allLinks);
  const lng = useSelector((state) => state.params.langage);

  const { t } = useTranslation();

  const openChat = () => {
    // simulate a click on this class : cc-dc5e
    const element = document.getElementsByClassName("cc-15e7")[0];

    element.click();
  };

  return (
    <div className="w-100 ">
      <div
        style={{ height: 300 }}
        className="container-fluid w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center"
      >
        <span style={{ width: "70%" }} className="job-details-title">
          {t("help:titrhelp").toUpperCase()}
        </span>
      </div>

      <div className="container">
        <div style={{ width: "100%" }} className="mt-3 ">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {/* {breadcrumbs} */}
            <span className="bread">
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                }}
                id="bread"
              >
                {t("rgpd:home")}
              </NavLink>
            </span>
            <span>{t("help:titrhelp")}</span>
          </Breadcrumbs>
        </div>

        <div className="w-100 mt-4 tw-mb-10 d-flex flex-column justify-content-center align-items-center ">
          <div className="w-100 tw-my-8 tw-ring-1 tw-py-3 tw-px-3 tw-rounded-md tw-ring-slate-600 position-relative tw-flex tw-items-right tw-flex-col md:tw-flex-col lg:tw-flex-row tw-justify-evenly job-details-header">
            <button
              onClick={openChat}
              className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black lg:tw-my-3 tw-my-1 tw-mx-2 "
            >
              <span className=" tw-mr-2 ">
                <img
                  src={require("../../assets/img/crisp.png")}
                  className=""
                  style={{ width: 80, height: 80 }}
                  alt="crisp"
                />
              </span>
              {t("help:onligne")}
            </button>

            <a
              href="mailto:hello@mylocker.fr"
              rel="noreferrer"
              className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black lg:tw-my-3 tw-my-1 tw-mx-2 "
            >
              <span className=" tw-mr-2 ">
                <img
                  src={require("../../assets/img/letter-email.png")}
                  className=""
                  style={{ width: 70, height: 70 }}
                  alt="instagram"
                />
              </span>
              {t("help:mail")}
            </a>

            <a
              target="_blank"
              href={links.whatsapp}
              rel="noreferrer"
              className=" tw-flex tw-flex-row tw-items-center hover:tw-text-black  lg:tw-my-3 tw-my-1 tw-mx-2 "
            >
              <span className=" tw-mr-2 ">
                <img
                  src={require("../../assets/img/whatsapp.png")}
                  className=""
                  style={{ width: 70, height: 70 }}
                  alt="whatsapp"
                />
              </span>
              {t("help:whatsapp")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineHelp;
