import axios from "axios";
import produce from "immer";
import { ContenairsApi } from "../../api/containersApi";

const GET_CONTAINER_STOCK = "GET_CONTAINER_STOCK";

export const getContainersDetails = (container_id) => async (dispatch) => {
  dispatch({
    type: "SET_CONTAINER_LOAD",
    payload: {
      loading: true,
      error: false,
    },
  });

  const res = await ContenairsApi.getContenairDetails(container_id);

  if (res) {
    dispatch({
      type: GET_CONTAINER_STOCK,
      payload: res,
    });
    dispatch({
      type: "SET_CONTAINER_LOAD",
      payload: {
        loading: false,
        error: false,
      },
    });
  } else {
    dispatch({
      type: "SET_CONTAINER_LOAD",
      payload: {
        loading: false,
        error: true,
      },
    });
  }
};

const containerappState = {
  containerDetails: {},

  containerDetailsLoad: {
    loading: false,
    error: false,
  },
};

const containerReducer = (state = containerappState, action) => {
  let nextState;

  switch (action.type) {
    case GET_CONTAINER_STOCK:
      nextState = produce(state, (draftState) => {
        draftState.containerDetails = action.payload;
      });

      return nextState || state;

    case "SET_CONTAINER_LOAD":
      nextState = produce(state, (draftState) => {
        draftState.containerDetailsLoad = action.payload;
      });

      return nextState || state;

    default:
      return state;
  }
};

export default containerReducer;
