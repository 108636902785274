import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_ROOT_API}`;

export default function Contact() {
  const [load, setLoad] = useState(false);
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    setLoad(true);
    axios
      .post(`${baseUrl}/public/contactForm`, data)
      .then((res) => {
        reset({ firstname: "", phone: "", email: "", body: "", objet: "" });
        NotificationManager.success(res.data.Message, "Success", 5000);
        setLoad(false);
      })
      .catch((err) => {
        NotificationManager.error(err, "Error", 5000);
        setLoad(false);
      });
  };

  return (
    <div
      className="w-100 container-fluid tw-flex-col tw-flex tw-items-center  "
      id="contact"
    >
      <div
        style={{ height: 300 }}
        className=" w-100 bg-danger job-details-header d-flex flex-column justify-content-center align-items-center"
      >
        <span style={{ width: "70%" }} className="job-details-title">
          {t("form:contactus").toUpperCase()}
        </span>
      </div>

      <div className="fieldset2 container position-relative tw-my-8 tw-px-6 col-md-6 col-12 py-4">
        <form id="contact_form tw-mt-[10px] " onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 tw-mt-2">
              <span className="form_label">{t("form:name")}</span>
              <div className="form_item">
                <input
                  type="text"
                  {...register("firstname", {
                    required: true,
                    message: "Veuillez ajouter votre nom",
                  })}
                  name="firstname"
                  placeholder={t("form:name")}
                />
              </div>
              <small className="text-danger">
                {" "}
                {errors.firstname && "Veuillez ajouter votre nom"}
              </small>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 tw-mt-2">
              <span className="form_label">{t("form:tel")}</span>
              <div className="form_item">
                <input
                  type="text"
                  {...register("phone", {
                    required: true,
                    message: "Veuillez ajouter votre téléphone ",
                  })}
                  name="phone"
                  placeholder={t("form:tel")}
                />
                <small className="text-danger">
                  {" "}
                  {errors.phone && "Veuillez ajouter votre numéro de téléphone"}
                </small>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tw-mt-2">
              <span className="form_label">{t("form:email")}</span>
              <div className="form_item">
                <input
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  name="email"
                  placeholder="Ex JohnDoe214@gmail.com"
                />
                <small className="text-danger">
                  {" "}
                  {errors.email && "Veuillez ajouter votre email"}
                </small>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tw-mt-2">
              <span className="form_label">{t("form:objet")}</span>
              <div className="form_item">
                <input
                  type="text"
                  name="text"
                  {...register("objet", {
                    required: true,
                    minLength: 2,
                  })}
                  placeholder="Objet"
                />
                <small className="text-danger">
                  {" "}
                  {errors.objet && "veuillez ajouter un objet à votre message"}
                </small>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tw-mt-2">
            <span className="form_label">{t("form:message")}</span>
            <div className="form_item">
              <textarea
                name="body"
                {...register("body", {
                  required: true,
                  minLength: 2,
                })}
                placeholder={t("form:messagePl")}
                defaultValue={""}
              />
            </div>
            <small className="text-danger">
              {" "}
              {errors.body && "veuillez ajouter un message"}
            </small>
          </div>

          <div className="abtn_wrap text-center clearfix">
            <button
              className="custom_btn btn_width bg_default_red tw-w-[200px] "
              type="submit"
            >
              {load ? t("form:sendonload") : t("form:send")}
            </button>
          </div>
        </form>

        <div
          className=" position-absolute px-3 d-flex"
          style={{
            top: -15,
            left: 50,
            backgroundColor: "#FFF8F8",
            height: 30,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="title-forfait">{t("form:contactus")}</span>
        </div>
      </div>
    </div>
  );
}
