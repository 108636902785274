const baseUrl = `${process.env.REACT_APP_PUBLIC_URL}`;
// const url = `${process.env.REACT_APP_PUBLIC_URL}/events`;

export const OtpApi = {
  async createOtp(dta) {
    const response = await fetch(baseUrl + `/clients/verify`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dta),
    });
    const data = await response.json();

    if (response.ok) return data;

    return null;
  },

  async verifyOtp(dta) {
    const response = await fetch(baseUrl + `/clients/verify_telephone`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(dta)
      body: JSON.stringify(dta),
    });
    const data = await response.json();

    if (response.ok) return data;

    return null;
  },
};
